import isEmpty from 'lodash/isEmpty'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import {
  ActivityResult,
  CorrectSpelling,
  FlashCardAssignmentType,
  FlashCardResponseType,
  FullScreenFlashCardType,
  Language,
  Maybe,
  StudentInteractionInput
} from '../../../data'
import { LessonViewUserAnswerType } from '../model/types'

type LessonItemType = Omit<StudentInteractionInput, 'assignedLearningFeatureId' | 'learningExecutionId'> & {
  timestamp: number
  isFinished: boolean
  activityResult: ActivityResult
  answers: LessonViewUserAnswerType[]
  correctSpelling?: Maybe<CorrectSpelling[]>
  fullScreenFlashCardType?: FullScreenFlashCardType
  flashCardAssignmentType?: FlashCardAssignmentType
  flashCardResponseType?: FlashCardResponseType
}

type LessonStoreType = {
  items: Record<string, LessonItemType>
  lessonTimestampStart: number[]
  lessonTimestampStop: number[]
  progress: number
  isPauseScreenVisible: boolean
  totalPoints: number
  newPoints: number
}

export type LessonStoreNewItem = {
  fullScreenFlashCardType?: FullScreenFlashCardType
  flashCardAssignmentType?: FlashCardAssignmentType
  flashCardResponseType?: FlashCardResponseType
  flashCardId: string
}

const initialState: LessonStoreType = {
  items: {},
  lessonTimestampStart: [],
  lessonTimestampStop: [],
  progress: 0,
  isPauseScreenVisible: false,
  totalPoints: 0,
  newPoints: 0
}

const useStore = createWithEqualityFn<LessonStoreType>(() => initialState, shallow)

export const lessonSessionStore = {
  createNewItem: ({
    flashCardAssignmentType,
    flashCardResponseType,
    fullScreenFlashCardType,
    flashCardId
  }: LessonStoreNewItem): void => {
    useStore.setState((state) => {
      let lessonTimestampStart = state.lessonTimestampStart

      if (isEmpty(state.items)) {
        lessonTimestampStart = [Date.now()]
      }

      const newItem: LessonItemType = {
        totalTimeMilliseconds: 0,
        reactionTimeMilliseconds: 0,
        responseInput: {
          textOfResponse: '',
          targetLanguage: Language.ENGLISH,
          sourceLanguage: Language.ENGLISH
        },
        activityResult: ActivityResult.NOT_INVOLVED,
        tryCount: 1,
        isSkipped: false,
        isFinished: false,
        flashCardAssignmentType,
        flashCardResponseType,
        fullScreenFlashCardType,
        flashCardId,
        correctSpelling: [],
        timestamp: Date.now(),
        answers: []
      }
      const items: Record<string, LessonItemType> = { ...state.items, [flashCardId]: newItem }

      return { items, lessonTimestampStart }
    })
  },

  // NOTE not need for now
  // getItem: (flashCardId: string): LessonItemType | undefined => {
  //   return useStore.getState().items[flashCardId]
  // },

  setIsSkipped: (flashCardId: string, newValue: boolean): void => {
    useStore.setState(({ items }) => {
      const item = items[flashCardId]
      if (!item) {
        return { items }
      }

      const newItem = { ...item }
      newItem.isSkipped = newValue

      return { items: { ...items, [flashCardId]: newItem } }
    })
  },

  setIsFinished: (flashCardId: string): void => {
    useStore.setState(({ items }) => {
      const item = items[flashCardId]
      if (!item) {
        return { items }
      }

      const newItem = { ...item }
      newItem.isFinished = true

      return { items: { ...items, [flashCardId]: newItem } }
    })
  },

  setResponseInput: (flashCardId: string, input: LessonItemType['responseInput']): void => {
    useStore.setState(({ items }) => {
      const item = items[flashCardId]
      if (!item) {
        return { items }
      }

      const newItem = { ...item }
      newItem.responseInput = input
      newItem.reactionTimeMilliseconds = Date.now() - item.timestamp
      newItem.timestamp = Date.now()

      return { items: { ...items, [flashCardId]: newItem } }
    })
  },

  setCorrectSpelling: (flashCardId: string, correctSpelling: LessonItemType['correctSpelling']): void => {
    useStore.setState(({ items }) => {
      const item = items[flashCardId]
      if (!item) {
        return { items }
      }

      const newItem = { ...item }
      newItem.correctSpelling = correctSpelling

      return { items: { ...items, [flashCardId]: newItem } }
    })
  },

  setAnswers: (flashCardId: string, answers: LessonViewUserAnswerType[]): void => {
    return useStore.setState(({ items }) => {
      const item = items[flashCardId]

      if (!item) {
        return { items }
      }

      const newItem = { ...item }

      newItem.answers = answers

      return { items: { ...items, [flashCardId]: newItem } }
    })
  },

  setProgress: (progress: number): void => {
    return useStore.setState({ progress })
  },

  setPoints: (totalPoints: number, points?: number): void => {
    return useStore.setState(({ newPoints }) => ({ totalPoints, newPoints: points || newPoints }))
  },

  addStartTimestamp: (): void => {
    return useStore.setState(({ lessonTimestampStart }) => {
      return {
        lessonTimestampStart: [...lessonTimestampStart, Date.now()]
      }
    })
  },

  addStopTimestamp: (): void => {
    return useStore.setState(({ lessonTimestampStop }) => {
      return {
        lessonTimestampStop: [...lessonTimestampStop, Date.now()]
      }
    })
  },

  clearStore: (): void => {
    useStore.setState(() => initialState, true)
  },

  useStore
}
