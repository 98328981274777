/* eslint-disable simple-import-sort/imports */
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as ApolloReactHooks from '@app/data/graphqlHooks'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** Scalar defined on BE - unified date format. */
  Date: string
  /** Unified date + time format */
  DateTime: string
}

export enum ActivityResult {
  ALMOST_CORRECT = 'ALMOST_CORRECT',
  CORRECT = 'CORRECT',
  NOT_INVOLVED = 'NOT_INVOLVED',
  SKIPPED = 'SKIPPED',
  WRONG = 'WRONG'
}

export type ApplicationSettings = {
  __typename?: 'ApplicationSettings'
  appLanguage: Language
}

export type ApplicationSettingsInput = {
  appLanguage?: InputMaybe<Language>
}

export type Book = {
  __typename?: 'Book'
  currentPage: Scalars['Int']
  id: Scalars['ID']
  image: ImageType
  label: Scalars['String']
  sourceUrl?: Maybe<Scalars['String']>
}

export type BugReport = {
  __typename?: 'BugReport'
  createdDate: Scalars['DateTime']
  externalId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  message?: Maybe<Scalars['String']>
  resolvedDate?: Maybe<Scalars['DateTime']>
  type: BugReportMessageType
}

export type BugReportMessageInput = {
  context: Scalars['String']
  message: Scalars['String']
  type: BugReportMessageType
}

export enum BugReportMessageType {
  BUG = 'BUG',
  SUGGESTION = 'SUGGESTION'
}

export enum CEFRLevel {
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  C1 = 'C1',
  C2 = 'C2'
}

export type Classroom = {
  __typename?: 'Classroom'
  book?: Maybe<Learning>
  code: Scalars['String']
  color?: Maybe<Scalars['String']>
  exerciseAssignments: Array<LearningAssignment>
  id: Scalars['ID']
  name: Scalars['String']
  organization: Organization
  pictureId?: Maybe<Scalars['String']>
  studentsInClassroom: Array<Student>
  subject: Subject
  testAssignments: Array<LearningAssignment>
  upcomingAssignments: Array<LearningAssignment>
  yearOfStudy: Scalars['String']
}

export type ClassroomInput = {
  bookId?: InputMaybe<Scalars['ID']>
  name: Scalars['String']
  pictureId?: InputMaybe<Scalars['String']>
  subject: Subject
}

export type ClassroomLogin = {
  __typename?: 'ClassroomLogin'
  classroomLoginCode: ClassroomLoginCode
  students: Array<UserProfile>
}

export type ClassroomLoginCode = {
  __typename?: 'ClassroomLoginCode'
  classroomId: Scalars['ID']
  classroomLoginCode: Scalars['String']
  expirationTime: Scalars['DateTime']
}

export type CompletedLearningExecution = {
  __typename?: 'CompletedLearningExecution'
  answers: Array<FlashCardAnswer>
  classRank: Scalars['Int']
  learningExecution: LearningExecution
  studentCount: Scalars['Int']
  studyTimeSeconds: Scalars['Int']
  summary?: Maybe<Scalars['String']>
  timestamp: Scalars['DateTime']
  totalSkippedCount: Scalars['Int']
}

export type ComposedFlashCard = FlashCard & {
  __typename?: 'ComposedFlashCard'
  assignedLearningFeatures: Array<LearningFeatureNew>
  flashCardAssignmentType: FlashCardAssignmentType
  flashCardResponseType: FlashCardResponseType
  id: Scalars['ID']
  learningExecutionProgress: Scalars['Int']
  learningExecutionState: LearningExecutionState
  responseLearningFeatures: Array<LearningFeatureNew>
  subjectLanguage: Language
  totalPoints: Scalars['Int']
}

export type CorrectSpelling = {
  __typename?: 'CorrectSpelling'
  letter: Scalars['String']
  spellingCorrectionType: SpellingCorrectionType
}

export enum Device {
  MOBILE = 'MOBILE',
  PROJECTOR = 'PROJECTOR',
  TABLET = 'TABLET'
}

export enum Difficulty {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MIDDLE = 'MIDDLE'
}

export type ExecutionExitInput = {
  elapsedTimeMilliseconds: Scalars['Int']
  learningExecutionId: Scalars['ID']
}

export type FlashCard = {
  id: Scalars['ID']
  learningExecutionProgress: Scalars['Int']
  learningExecutionState: LearningExecutionState
  totalPoints: Scalars['Int']
}

export type FlashCardAnswer = {
  __typename?: 'FlashCardAnswer'
  flashCardAssignmentType?: Maybe<FlashCardAssignmentType>
  flashCardFullScreenType?: Maybe<FullScreenFlashCardType>
  flashCardId: Scalars['ID']
  flashCardResponseType?: Maybe<FlashCardResponseType>
  points: Scalars['Int']
  skipCount: Scalars['Int']
  tryCount: Scalars['Int']
  userAnswers: Array<UserAnswer>
}

export enum FlashCardAssignmentType {
  IMAGE = 'IMAGE',
  SOUND = 'SOUND',
  TEXT = 'TEXT',
  VIDEO = 'VIDEO'
}

export enum FlashCardResponseType {
  CHOICE_IMAGE = 'CHOICE_IMAGE',
  CHOICE_TEXT = 'CHOICE_TEXT',
  SCRABBLE = 'SCRABBLE',
  SOUND = 'SOUND',
  TEXT = 'TEXT',
  WORD_PICKING = 'WORD_PICKING'
}

export type FrequentMistakes = {
  __typename?: 'FrequentMistakes'
  learningFeature: LearningFeatureNew
  mistakeCount: Scalars['Int']
}

export type FullScreenFlashCard = FlashCard & {
  __typename?: 'FullScreenFlashCard'
  assignedLearningFeatures: Array<LearningFeatureNew>
  fullScreenFlashCardType: FullScreenFlashCardType
  id: Scalars['ID']
  learningExecutionProgress: Scalars['Int']
  learningExecutionState: LearningExecutionState
  responseLearningFeatures: Array<LearningFeatureNew>
  subjectLanguage: Language
  totalPoints: Scalars['Int']
}

export enum FullScreenFlashCardType {
  SHOPPING_CART = 'SHOPPING_CART',
  SOUND_TO_TEXT_CONNECTION = 'SOUND_TO_TEXT_CONNECTION',
  SWIPE_MATCH = 'SWIPE_MATCH',
  TEXT_TO_IMAGE_CONNECTION = 'TEXT_TO_IMAGE_CONNECTION',
  TEXT_TO_TEXT_CONNECTION = 'TEXT_TO_TEXT_CONNECTION',
  WHEEL_OF_FORTUNE_IMAGE = 'WHEEL_OF_FORTUNE_IMAGE',
  WHEEL_OF_FORTUNE_TEXT = 'WHEEL_OF_FORTUNE_TEXT'
}

export enum GQLWorksheetLexicalType {
  ANTONYMS = 'ANTONYMS',
  DEFINITIONS = 'DEFINITIONS',
  SYNONYMS = 'SYNONYMS',
  TRANSLATIONS = 'TRANSLATIONS'
}

export enum GQLWorksheetType {
  MATCH_ELEMENTS = 'MATCH_ELEMENTS',
  ONE_TO_MANY = 'ONE_TO_MANY',
  PUZZLE = 'PUZZLE'
}

export type GameProperties = {
  __typename?: 'GameProperties'
  coverStyle?: Maybe<Scalars['String']>
  isSoundEnabled?: Maybe<Scalars['Boolean']>
  questionCount?: Maybe<Scalars['Int']>
  secondsPerQuestion?: Maybe<Scalars['Int']>
  showQuestionInClient?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  worksheetLexicalType?: Maybe<Scalars['String']>
  worksheetType?: Maybe<Scalars['String']>
}

export type GamePropertiesInput = {
  coverStyle?: InputMaybe<Scalars['String']>
  isSoundEnabled?: InputMaybe<Scalars['Boolean']>
  questionCount?: InputMaybe<Scalars['Int']>
  secondsPerQuestion?: InputMaybe<Scalars['Int']>
  showQuestionInClient?: InputMaybe<Scalars['Boolean']>
  title?: InputMaybe<Scalars['String']>
  worksheetLexicalType?: InputMaybe<Scalars['String']>
  worksheetType?: InputMaybe<Scalars['String']>
}

export type ImageType = {
  __typename?: 'ImageType'
  default: Scalars['String']
  large?: Maybe<Scalars['String']>
  medium?: Maybe<Scalars['String']>
  small?: Maybe<Scalars['String']>
  thumbnail?: Maybe<Scalars['String']>
}

export type InfoFlashCard = FlashCard & {
  __typename?: 'InfoFlashCard'
  id: Scalars['ID']
  informationType: InformationFlashCardType
  learningExecutionProgress: Scalars['Int']
  learningExecutionState: LearningExecutionState
  totalPoints: Scalars['Int']
}

export enum InformationFlashCardType {
  LISTENING = 'LISTENING',
  MISTAKES = 'MISTAKES',
  PROGRESS = 'PROGRESS',
  READING = 'READING',
  SPEAKING = 'SPEAKING',
  WRITING = 'WRITING'
}

export enum InteractionType {
  LISTENING = 'LISTENING',
  SPEAKING = 'SPEAKING'
}

export enum Language {
  CZECH = 'CZECH',
  ENGLISH = 'ENGLISH',
  FRENCH = 'FRENCH',
  GERMAN = 'GERMAN',
  ITALIAN = 'ITALIAN',
  SPANISH = 'SPANISH'
}

export type Learning = {
  __typename?: 'Learning'
  children?: Maybe<Array<Learning>>
  id: Scalars['ID']
  image?: Maybe<ImageType>
  imageURL?: Maybe<Scalars['String']>
  learningFeatureCount: Scalars['Int']
  learningFeatures: Array<LearningFeatureNew>
  level: CEFRLevel
  name: Scalars['String']
  sourceLanguage: Language
  subject: Subject
  type: LearningType
}

export type LearningActivitiesSearchInput = {
  activityStates: Array<LearningActivityState>
  activityTypes: Array<LearningActivityType>
  classroomIds: Array<Scalars['ID']>
  learningPlanLessonIds: Array<Scalars['ID']>
  textSearch?: InputMaybe<Scalars['String']>
  withLearningExecutions: Scalars['Boolean']
  withLearningFeatures: Scalars['Boolean']
}

export type LearningActivity = {
  __typename?: 'LearningActivity'
  devices: Array<Device>
  durations: Array<LearningActivityDuration>
  estimatedTimeInSeconds: Scalars['Int']
  executions?: Maybe<Array<LearningExecution>>
  gameProperties?: Maybe<GameProperties>
  id: Scalars['ID']
  isInShelf: Scalars['Boolean']
  learningActivityExecutionId?: Maybe<Scalars['ID']>
  learningActivityType: LearningActivityType
  learningFeatureCount: Scalars['Int']
  learningFeatureFilter: Array<LearningFeatureType>
  learningFeatures: Array<LearningFeatureNew>
  learningPlanLessonId: Scalars['ID']
  level1Name?: Maybe<Scalars['String']>
  level2Name?: Maybe<Scalars['String']>
  name: Scalars['String']
  previousActivityId?: Maybe<Scalars['ID']>
  progress?: Maybe<LearningActivityProgress>
  skills: Array<LearningActivitySkill>
  state: LearningActivityState
  subject: Subject
  tags: Array<Scalars['String']>
}

export type LearningActivityDuration = {
  __typename?: 'LearningActivityDuration'
  durationInSeconds: Scalars['Int']
  isActive: Scalars['Boolean']
}

export type LearningActivityDurationInput = {
  durationInSeconds: Scalars['Int']
  isActive: Scalars['Boolean']
}

export type LearningActivityInput = {
  deadline?: InputMaybe<Scalars['DateTime']>
  durations?: InputMaybe<Array<LearningActivityDurationInput>>
  gameProperties?: InputMaybe<GamePropertiesInput>
  isInShelf: Scalars['Boolean']
  learningActivitiesOrder?: InputMaybe<Array<Scalars['ID']>>
  learningActivityType?: InputMaybe<LearningActivityType>
  learningFeatureFilter?: InputMaybe<Array<LearningFeatureType>>
  learningFeatures?: InputMaybe<Array<Scalars['ID']>>
  name?: InputMaybe<Scalars['String']>
  skills?: InputMaybe<Array<LearningActivitySkillInput>>
  tags?: InputMaybe<Array<Scalars['String']>>
}

export type LearningActivityParticipantResult = {
  __typename?: 'LearningActivityParticipantResult'
  answers: Array<FlashCardAnswer>
  correctAnswers?: Maybe<Scalars['Int']>
  interactions?: Maybe<Scalars['Int']>
  interventionNeeded: Scalars['Boolean']
  learningActivityId: Scalars['ID']
  participant: Participant
  points?: Maybe<Scalars['Int']>
  progress?: Maybe<Scalars['Int']>
  successMeasure?: Maybe<Scalars['Int']>
  totalAnswers?: Maybe<Scalars['Int']>
}

export type LearningActivityProgress = {
  __typename?: 'LearningActivityProgress'
  deadline?: Maybe<Scalars['DateTime']>
  progress?: Maybe<Scalars['Int']>
  total?: Maybe<Scalars['Int']>
  type?: Maybe<LearningActivityProgressType>
}

export type LearningActivityProgressInput = {
  deadline?: InputMaybe<Scalars['DateTime']>
  progress?: InputMaybe<Scalars['Int']>
  total?: InputMaybe<Scalars['Int']>
  type?: InputMaybe<LearningActivityProgressType>
}

export enum LearningActivityProgressType {
  COUNT = 'COUNT',
  PERCENTAGE = 'PERCENTAGE',
  TIME_IN_SECONDS = 'TIME_IN_SECONDS'
}

export type LearningActivityReportProgress = {
  __typename?: 'LearningActivityReportProgress'
  interventionNeeded: Scalars['Boolean']
  progress: Scalars['Int']
  progressChange: ProgressChange
  total: Scalars['Int']
}

export type LearningActivityResult = {
  __typename?: 'LearningActivityResult'
  frequentMistakes: Array<FrequentMistakes>
  learningActivityId: Scalars['ID']
  participantResults: Array<LearningActivityParticipantResult>
  skillResults: Array<LearningActivitySkillResult>
  timestamp: Scalars['DateTime']
}

export type LearningActivitySkill = {
  __typename?: 'LearningActivitySkill'
  isActive: Scalars['Boolean']
  skill: Skill
}

export type LearningActivitySkillInput = {
  isActive: Scalars['Boolean']
  skill: Skill
}

export type LearningActivitySkillResult = {
  __typename?: 'LearningActivitySkillResult'
  skill: UserStatisticsSkills
  successMeasure: Scalars['Int']
}

export enum LearningActivityState {
  EXPIRED = 'EXPIRED',
  FINISHED = 'FINISHED',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING = 'WAITING'
}

export enum LearningActivityType {
  FLIPCARDS = 'FLIPCARDS',
  HOMEWORK = 'HOMEWORK',
  HUNTWORDS = 'HUNTWORDS',
  PEXESO = 'PEXESO',
  QUIZ = 'QUIZ',
  SELF_STUDY = 'SELF_STUDY',
  TEST = 'TEST',
  WHEEL = 'WHEEL',
  WORKSHEETS = 'WORKSHEETS'
}

export type LearningAssignment = {
  __typename?: 'LearningAssignment'
  assignedByTeacher?: Maybe<Scalars['Boolean']>
  averageSuccessMeasure?: Maybe<Scalars['Int']>
  difficulty: Difficulty
  endDateAndTime?: Maybe<Scalars['DateTime']>
  executions: Array<LearningExecution>
  id: Scalars['ID']
  image?: Maybe<ImageType>
  imageURL?: Maybe<Scalars['String']>
  learningFeatureCount: Scalars['Int']
  learningFeatures: Array<LearningFeatureNew>
  learnings: Array<Learning>
  level: CEFRLevel
  name: Scalars['String']
  notes?: Maybe<Scalars['String']>
  skills: Array<Skill>
  startDateAndTime: Scalars['DateTime']
  state: LearningAssignmentState
  studentsCount?: Maybe<Scalars['Int']>
  subject: Subject
  submissionCount?: Maybe<Scalars['Int']>
  timeLimitSeconds?: Maybe<Scalars['Int']>
  type: LearningAssignmentType
}

export type LearningAssignmentInput = {
  classroomId: Scalars['ID']
  endDateTime?: InputMaybe<Scalars['DateTime']>
  learningActivityId?: InputMaybe<Scalars['ID']>
  learningIds: Array<Scalars['ID']>
  name: Scalars['String']
  skills?: InputMaybe<Array<Skill>>
  state: LearningAssignmentState
  studentIds: Array<Scalars['ID']>
  subject: Subject
  timeLimitSeconds?: InputMaybe<Scalars['Int']>
  type: LearningAssignmentType
}

export enum LearningAssignmentState {
  CONCEPT = 'CONCEPT',
  DELETED = 'DELETED',
  EXPIRED = 'EXPIRED',
  FINISHED = 'FINISHED',
  IN_PROGRESS = 'IN_PROGRESS'
}

export enum LearningAssignmentType {
  EXERCISE = 'EXERCISE',
  HOMEWORK = 'HOMEWORK',
  LEARNING_PATH_LESSON_EXERCISE = 'LEARNING_PATH_LESSON_EXERCISE',
  PERSONALIZED_REPETITION = 'PERSONALIZED_REPETITION',
  REPETITION = 'REPETITION',
  STUDENT_LIBRARY_EXERCISE = 'STUDENT_LIBRARY_EXERCISE',
  TEST = 'TEST'
}

export type LearningExecution = {
  __typename?: 'LearningExecution'
  id: Scalars['ID']
  learningAssignment: LearningAssignment
  name: Scalars['String']
  progress: Scalars['Int']
  remainingTimeSeconds?: Maybe<Scalars['Int']>
  state: LearningExecutionState
  student: Student
  successMeasure: Scalars['Int']
}

export type LearningExecutionReport = {
  __typename?: 'LearningExecutionReport'
  executions: Array<CompletedLearningExecution>
  learningActivityType: LearningActivityType
  progress: LearningActivityReportProgress
}

export enum LearningExecutionState {
  DELETED = 'DELETED',
  EXPIRED = 'EXPIRED',
  FINISHED = 'FINISHED',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING = 'WAITING'
}

export type LearningExecutionStatistics = {
  __typename?: 'LearningExecutionStatistics'
  learningFeatureCount: Scalars['Int']
  progress: Scalars['Int']
  successMeasure: Scalars['Int']
  successfulLearningFeatureCount: Scalars['Int']
  totalSkippedCount: Scalars['Int']
}

export type LearningExecutionStatisticsInput = {
  endDateTime: Scalars['DateTime']
  learningExecutionId: Scalars['ID']
  learningTimeSeconds: Scalars['Int']
}

export type LearningFeatureDefinition = {
  __typename?: 'LearningFeatureDefinition'
  value: Scalars['String']
}

export type LearningFeatureLexicalInput = {
  cefrLevels: Array<CEFRLevel>
  ids: Array<Scalars['ID']>
  type: LearningFeatureLexicalType
}

export type LearningFeatureLexicalItem = {
  __typename?: 'LearningFeatureLexicalItem'
  cefrLevel: CEFRLevel
  feature: LearningFeatureLexicalUnion
  id: Scalars['ID']
  type: LearningFeatureLexicalType
}

export enum LearningFeatureLexicalType {
  ANTONYM = 'ANTONYM',
  DEFINITION = 'DEFINITION',
  SYNONYM = 'SYNONYM'
}

export type LearningFeatureLexicalUnion = LearningFeatureDefinition | LearningFeatureNew

export type LearningFeatureNew = {
  __typename?: 'LearningFeatureNew'
  hasAntonyms: Scalars['Boolean']
  hasBeenSeen: Scalars['Boolean']
  hasDefinitions: Scalars['Boolean']
  hasSynonyms: Scalars['Boolean']
  id: Scalars['ID']
  images: Array<ImageType>
  level?: Maybe<CEFRLevel>
  origin: LearningFeatureNewOrigin
  sourceLanguage: Language
  sourceLanguageText: Scalars['String']
  sourceSoundUrls: Array<Scalars['String']>
  sourceVideoUrls: Array<Scalars['String']>
  targetLanguage: Language
  targetLanguageText: Scalars['String']
  targetSoundUrls: Array<Scalars['String']>
  targetVideoUrls: Array<Scalars['String']>
  type: LearningFeatureType
}

export enum LearningFeatureNewOrigin {
  BOOK = 'BOOK',
  BOOK_DISABLED = 'BOOK_DISABLED',
  CATALOGUE = 'CATALOGUE',
  USER = 'USER'
}

export enum LearningFeatureState {
  CONFIRMED = 'CONFIRMED',
  CRITICAL = 'CRITICAL',
  MASTERED = 'MASTERED',
  PROGRESSING = 'PROGRESSING',
  REVISED = 'REVISED'
}

export enum LearningFeatureType {
  MULTIWORD_EXPRESSION = 'MULTIWORD_EXPRESSION',
  PHRASE = 'PHRASE',
  SENTENCE = 'SENTENCE',
  WORD = 'WORD'
}

export type LearningPathNode = {
  __typename?: 'LearningPathNode'
  assignmentIndex?: Maybe<Scalars['Int']>
  assignmentState: LearningPathNodeState
  assignmentType?: Maybe<LearningAssignmentType>
  deadline?: Maybe<Scalars['DateTime']>
  executionId?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  isPersistent: Scalars['Boolean']
  name: Scalars['String']
  progress: LearningPathNodeProgress
  subject: Subject
  type: LearningPathNodeType
}

export type LearningPathNodeProgress = {
  __typename?: 'LearningPathNodeProgress'
  progress: Scalars['Int']
  total: Scalars['Int']
}

export enum LearningPathNodeState {
  FINISHED = 'FINISHED',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING = 'WAITING'
}

export enum LearningPathNodeType {
  ASSIGNMENT = 'ASSIGNMENT',
  BLOCKER = 'BLOCKER',
  LESSON = 'LESSON',
  UNIT = 'UNIT'
}

export type LearningPathResponse = {
  __typename?: 'LearningPathResponse'
  classroomId: Scalars['ID']
  currentNode: Scalars['ID']
  nextNode?: Maybe<Scalars['ID']>
  nodes: Array<LearningPathNode>
  previousNode?: Maybe<Scalars['ID']>
  studentId: Scalars['ID']
}

export type LearningPlanLesson = {
  __typename?: 'LearningPlanLesson'
  books?: Maybe<Array<Book>>
  content?: Maybe<Array<LearningFeatureNew>>
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isCurrent: Scalars['Boolean']
  isUserOwned?: Maybe<Scalars['Boolean']>
  learningActivities?: Maybe<Array<LearningActivity>>
  level1Name?: Maybe<Scalars['String']>
  level2Name?: Maybe<Scalars['String']>
  order1: Scalars['Int']
  previousLessonId?: Maybe<Scalars['ID']>
  userContent?: Maybe<Array<LearningFeatureNew>>
}

export type LearningPlanLessonInput = {
  content?: InputMaybe<Array<Scalars['ID']>>
  description?: InputMaybe<Scalars['String']>
  level1Name?: InputMaybe<Scalars['String']>
  level2Name: Scalars['String']
  previousLessonId?: InputMaybe<Scalars['ID']>
}

export enum LearningType {
  CATEGORY = 'CATEGORY',
  LESSON = 'LESSON',
  LEVEL = 'LEVEL',
  PACKAGE = 'PACKAGE',
  PROGRAM = 'PROGRAM',
  SUBJECT = 'SUBJECT',
  SUBPACKAGE = 'SUBPACKAGE',
  UNIT = 'UNIT'
}

export type Library = {
  __typename?: 'Library'
  books: Array<Learning>
  repetitions: Array<Learning>
}

export type LoginToken = {
  __typename?: 'LoginToken'
  accessToken?: Maybe<Scalars['String']>
  classroomLoginCode?: Maybe<ClassroomLoginCode>
  refreshToken?: Maybe<Scalars['String']>
  studentId: Scalars['ID']
}

export type Mutation = {
  __typename?: 'Mutation'
  addMeToClassroom: Scalars['Boolean']
  addUserLearningFeature: LearningFeatureNew
  addUserRole: Scalars['Boolean']
  answerQuizQuestion: Scalars['Boolean']
  bugReport: Scalars['ID']
  confirmReleaseNotes: Scalars['Boolean']
  createAndEnrollLearningAssignment: LearningAssignment
  createClassroom: Classroom
  createLearningActivity: LearningActivity
  createLearningPlanLesson: LearningPlanLesson
  deleteClassroom: Scalars['Boolean']
  deleteLearningActivity: Scalars['Boolean']
  deleteLearningPlanLesson: Scalars['Boolean']
  deleteStudent: Scalars['Boolean']
  deleteUserLearningFeature: Scalars['Boolean']
  editClassroom: Classroom
  joinQuizGame: QuizGame
  leaveQuizGame: Scalars['Boolean']
  nextQuizQuestion: Scalars['Boolean']
  processExecutionExit: Scalars['Boolean']
  processLearningExecutionStatistics: LearningExecutionStatistics
  processStudentInteraction: ResponseInteraction
  removeStudentFromClass: Scalars['Boolean']
  reportBugInLearningFeature: Scalars['ID']
  startLearningActivity: LearningActivity
  startLearningPathNodeExecution: Scalars['ID']
  startQuizGame: QuizGame
  startStudentLibrarySubPackage: Scalars['ID']
  stopLearningActivity: LearningActivity
  updateAppSettings: ApplicationSettings
  updateLearningActivity: LearningActivity
  updateLearningPlanLesson: LearningPlanLesson
  updateStudentLibrarySubPackage: Scalars['Boolean']
  updateStudentProfile: Student
  updateTutorialProgress: TutorialProgress
  updateUserLearningFeature: LearningFeatureNew
  updateUserProfile: UserProfile
  userBulkRegistration: UserBulkRegistrationEnvelopeResponse
  userBulkVerification: Array<UserBulkRegistrationResponse>
}

export type MutationAddMeToClassroomArgs = {
  classroomCode: Scalars['String']
  classroomId: Scalars['ID']
}

export type MutationAddUserLearningFeatureArgs = {
  userLearningFeature?: InputMaybe<UserLearningFeatureInput>
}

export type MutationAddUserRoleArgs = {
  userRole: UserRole
}

export type MutationAnswerQuizQuestionArgs = {
  answer: Scalars['Int']
  questionId: Scalars['ID']
  quizId: Scalars['ID']
  timestamp?: InputMaybe<Scalars['DateTime']>
}

export type MutationBugReportArgs = {
  message: BugReportMessageInput
}

export type MutationConfirmReleaseNotesArgs = {
  id: Scalars['ID']
}

export type MutationCreateAndEnrollLearningAssignmentArgs = {
  learningAssignmentInput: LearningAssignmentInput
}

export type MutationCreateClassroomArgs = {
  classroomInput: ClassroomInput
}

export type MutationCreateLearningActivityArgs = {
  learningActivityInput: LearningActivityInput
  learningPlanLessonId: Scalars['ID']
}

export type MutationCreateLearningPlanLessonArgs = {
  classroomId: Scalars['ID']
  learningPlanLessonInput: LearningPlanLessonInput
}

export type MutationDeleteClassroomArgs = {
  classroomId: Scalars['ID']
}

export type MutationDeleteLearningActivityArgs = {
  learningActivitiesOrder: Array<Scalars['ID']>
  learningActivityId: Scalars['ID']
}

export type MutationDeleteLearningPlanLessonArgs = {
  learningPlanLessonId: Scalars['ID']
}

export type MutationDeleteStudentArgs = {
  studentId: Scalars['ID']
}

export type MutationDeleteUserLearningFeatureArgs = {
  learningFeatureId: Scalars['ID']
}

export type MutationEditClassroomArgs = {
  classroomId: Scalars['ID']
  classroomInput: ClassroomInput
}

export type MutationJoinQuizGameArgs = {
  code: Scalars['String']
}

export type MutationLeaveQuizGameArgs = {
  quizId: Scalars['ID']
}

export type MutationNextQuizQuestionArgs = {
  quizId: Scalars['ID']
}

export type MutationProcessExecutionExitArgs = {
  exerciseExitInput: ExecutionExitInput
}

export type MutationProcessLearningExecutionStatisticsArgs = {
  learningExecutionStatisticsInput: LearningExecutionStatisticsInput
}

export type MutationProcessStudentInteractionArgs = {
  studentInteractionInput: StudentInteractionInput
}

export type MutationRemoveStudentFromClassArgs = {
  classroomId: Scalars['ID']
  studentId: Scalars['ID']
}

export type MutationReportBugInLearningFeatureArgs = {
  appVersion: Scalars['String']
  bugDescription: Scalars['String']
  flashCardAssignmentType?: InputMaybe<FlashCardAssignmentType>
  flashCardFullScreenType?: InputMaybe<FullScreenFlashCardType>
  flashCardResponseType?: InputMaybe<FlashCardResponseType>
  learningFeatureId?: InputMaybe<Scalars['ID']>
  logMessage?: InputMaybe<Scalars['String']>
  os: Scalars['String']
  userId: Scalars['ID']
}

export type MutationStartLearningActivityArgs = {
  learningActivityId: Scalars['ID']
}

export type MutationStartLearningPathNodeExecutionArgs = {
  input: StartLearningPathNodeExecutionInput
}

export type MutationStartQuizGameArgs = {
  quizGameInput: QuizGameInput
}

export type MutationStartStudentLibrarySubPackageArgs = {
  level: StudentLibraryRankLevel
  subPackageId: Scalars['ID']
}

export type MutationStopLearningActivityArgs = {
  learningActivityId: Scalars['ID']
}

export type MutationUpdateAppSettingsArgs = {
  applicationSettings?: InputMaybe<ApplicationSettingsInput>
}

export type MutationUpdateLearningActivityArgs = {
  learningActivityId: Scalars['ID']
  learningActivityInput: LearningActivityInput
}

export type MutationUpdateLearningPlanLessonArgs = {
  learningPlanLessonId: Scalars['ID']
  learningPlanLessonInput: LearningPlanLessonInput
}

export type MutationUpdateStudentLibrarySubPackageArgs = {
  input: StudentLibrarySubPackageInput
  subPackageId: Scalars['ID']
}

export type MutationUpdateStudentProfileArgs = {
  studentId: Scalars['ID']
  userProfile: UserProfileInput
}

export type MutationUpdateTutorialProgressArgs = {
  isSkipped: Scalars['Boolean']
  tutorialStepCompleted: Scalars['Int']
}

export type MutationUpdateUserLearningFeatureArgs = {
  learningFeatureId: Scalars['ID']
  userLearningFeature?: InputMaybe<UserLearningFeatureInput>
}

export type MutationUpdateUserProfileArgs = {
  userProfile?: InputMaybe<UserProfileInput>
}

export type MutationUserBulkRegistrationArgs = {
  classroomId: Scalars['ID']
  language: Language
  users: Array<UserBulkRegistration>
}

export type MutationUserBulkVerificationArgs = {
  classroomId: Scalars['ID']
  users: Array<UserBulkRegistration>
}

export type NextFlashCardInput = {
  buffered?: InputMaybe<Scalars['Boolean']>
  interactionTypes: Array<InteractionType>
  learningExecutionId: Scalars['ID']
  supportedFlashCardAssignmentTypes: Array<FlashCardAssignmentType>
  supportedFlashCardResponseTypes: Array<FlashCardResponseType>
  supportedFullScreenFlashCardTypes: Array<FullScreenFlashCardType>
}

export type Organization = {
  __typename?: 'Organization'
  children: Array<Organization>
  id: Scalars['ID']
  name: Scalars['String']
  type: OrganizationType
}

export enum OrganizationType {
  CLASSROOM = 'CLASSROOM',
  SCHOOL = 'SCHOOL'
}

export type PageableInput = {
  count: Scalars['Int']
  from: Scalars['Int']
}

export enum PartOfSpeech {
  ADJECTIVE = 'ADJECTIVE',
  ADVERB = 'ADVERB',
  NOUN = 'NOUN',
  PRONOUN = 'PRONOUN',
  VERB = 'VERB'
}

export type Participant = Student | Team

export enum ProgressChange {
  DECREASE = 'DECREASE',
  INCREASE = 'INCREASE',
  NO_CHANGE = 'NO_CHANGE'
}

export type PronunciationInput = {
  accuracyScore: Scalars['Float']
  completenessScore: Scalars['Float']
  fluencyScore: Scalars['Float']
  pronScore: Scalars['Float']
  prosodyScore: Scalars['Float']
}

export type Query = {
  __typename?: 'Query'
  books: Array<Learning>
  bugReports: Array<BugReport>
  classroom: Classroom
  classroomHomeworks: Array<LearningActivity>
  classroomLoginCode: ClassroomLoginCode
  classroomPerformanceReport: Array<StudentPerformanceReportEnvelope>
  classrooms: Array<Classroom>
  completedLearningExecution: CompletedLearningExecution
  learning?: Maybe<Learning>
  learningActivity: LearningActivity
  learningActivityResults: Array<LearningActivityResult>
  learningFeatureLexicon: Array<LearningFeatureLexicalItem>
  learningPath: LearningPathResponse
  learningPlanLessons: Array<LearningPlanLesson>
  library: Library
  loginStudent: LoginToken
  nextFlashCard: FlashCard
  organizations: Array<Organization>
  quizGame: QuizGame
  refreshToken: LoginToken
  releaseNotes: Array<ReleaseNotes>
  revisionLearningExecutions: Array<LearningExecution>
  searchDictionaryLearningFeature: Array<LearningFeatureNew>
  searchLearningActivities: Array<LearningActivity>
  searchLearningFeature: Array<LearningFeatureNew>
  studentAppSupportedVersion: SupportedVersion
  studentDetail: StudentDetail
  studentInfo: StudentInfo
  studentLibraryDetail: StudentLibrarySubPackage
  studentLibraryOverview: StudentLibraryOverview
  studentPerformanceDetailReport: StudentPerformanceReportEnvelope
  studentRanks: Array<StudentRank>
  studentStatistics: StudentStatistics
  students: ClassroomLogin
  subjects: Array<Subject>
  tasks: Array<LearningExecution>
  tutorialProgress: TutorialProgress
  userProfile: UserProfile
  worksheetsAllowedTypes: Array<WorksheetAllowedType>
  worksheetsForActivity: Array<WorksheetPdf>
}

export type QueryBooksArgs = {
  subject: Subject
}

export type QueryBugReportsArgs = {
  page: PageableInput
}

export type QueryClassroomArgs = {
  classroomId: Scalars['ID']
}

export type QueryClassroomHomeworksArgs = {
  classroomId: Scalars['ID']
}

export type QueryClassroomLoginCodeArgs = {
  classroomId: Scalars['ID']
}

export type QueryClassroomPerformanceReportArgs = {
  classroomId: Scalars['ID']
}

export type QueryCompletedLearningExecutionArgs = {
  learningExecutionId: Scalars['ID']
}

export type QueryLearningArgs = {
  learningId: Scalars['ID']
}

export type QueryLearningActivityArgs = {
  learningActivityId: Scalars['ID']
}

export type QueryLearningActivityResultsArgs = {
  learningActivityId: Scalars['ID']
}

export type QueryLearningFeatureLexiconArgs = {
  input: LearningFeatureLexicalInput
}

export type QueryLearningPathArgs = {
  classroomId: Scalars['ID']
  learningPlanUnitId?: InputMaybe<Scalars['ID']>
}

export type QueryLearningPlanLessonsArgs = {
  classroomId: Scalars['ID']
  learningPlanLessonIds?: InputMaybe<Array<Scalars['ID']>>
  withDetail?: InputMaybe<Scalars['Boolean']>
  withLearningFeatures?: InputMaybe<Scalars['Boolean']>
}

export type QueryLoginStudentArgs = {
  classroomLoginCode: Scalars['String']
  studentId: Scalars['ID']
}

export type QueryNextFlashCardArgs = {
  nextFlashCardInput: NextFlashCardInput
}

export type QueryQuizGameArgs = {
  quizId: Scalars['ID']
}

export type QueryRefreshTokenArgs = {
  refreshToken: Scalars['String']
}

export type QueryReleaseNotesArgs = {
  input: ReleaseNotesInput
  page: PageableInput
}

export type QuerySearchDictionaryLearningFeatureArgs = {
  filter?: InputMaybe<SearchDictionaryFilterInput>
  page: PageableInput
  searchLearningFeatureInput: SearchDictionaryLearningFeatureInput
}

export type QuerySearchLearningActivitiesArgs = {
  page: PageableInput
  parameters: LearningActivitiesSearchInput
}

export type QuerySearchLearningFeatureArgs = {
  searchLearningFeatureInput: SearchLearningFeatureInput
}

export type QueryStudentDetailArgs = {
  studentId: Scalars['ID']
}

export type QueryStudentLibraryDetailArgs = {
  subPackageId: Scalars['ID']
}

export type QueryStudentLibraryOverviewArgs = {
  subject?: InputMaybe<Subject>
}

export type QueryStudentPerformanceDetailReportArgs = {
  classroomId: Scalars['ID']
  page?: InputMaybe<PageableInput>
  studentId: Scalars['ID']
  type?: InputMaybe<LearningActivityType>
}

export type QueryStudentRanksArgs = {
  classroomId: Scalars['ID']
  timeView?: InputMaybe<TimeView>
}

export type QueryStudentStatisticsArgs = {
  userId: Scalars['ID']
}

export type QueryStudentsArgs = {
  classroomLoginCode: Scalars['String']
}

export type QueryTasksArgs = {
  classroomId?: InputMaybe<Scalars['ID']>
  isActive: Scalars['Boolean']
  page?: InputMaybe<PageableInput>
}

export type QueryWorksheetsForActivityArgs = {
  learningActivityId: Scalars['ID']
}

export enum QuizAssignmentType {
  IMAGE = 'IMAGE',
  SOUND = 'SOUND',
  TEXT = 'TEXT',
  VIDEO = 'VIDEO'
}

export type QuizGame = {
  __typename?: 'QuizGame'
  code: Scalars['String']
  connectedPlayers: Array<Maybe<QuizGamePlayer>>
  countdownInSeconds: Scalars['Int']
  currentQuestion?: Maybe<QuizGameQuestion>
  gameState: QuizGameState
  id: Scalars['ID']
  name: Scalars['String']
  numOfQuestions: Scalars['Int']
  secondsPerQuestion: Scalars['Int']
  showQuestionInClient: Scalars['Boolean']
}

export type QuizGameInput = {
  secondsPerQuestion: Scalars['Int']
  showQuestionInClient: Scalars['Boolean']
  socialGameId: Scalars['ID']
}

export type QuizGamePlayer = {
  __typename?: 'QuizGamePlayer'
  firstName: Scalars['String']
  id: Scalars['ID']
  lastName: Scalars['String']
  name: Scalars['String']
  newPoints: Scalars['Int']
  newPosition: Scalars['Int']
  oldPosition: Scalars['Int']
  pictureUrl?: Maybe<Scalars['String']>
  totalPoints: Scalars['Int']
}

export type QuizGameQuestion = {
  __typename?: 'QuizGameQuestion'
  assignment: QuizGameQuestionItem
  assignmentType: QuizAssignmentType
  hasNext: Scalars['Boolean']
  id: Scalars['ID']
  index: Scalars['Int']
  numOfAnswers: Scalars['Int']
  questionResult: QuizGameQuestionResult
  responses: Array<QuizGameQuestionItem>
}

export type QuizGameQuestionItem = {
  __typename?: 'QuizGameQuestionItem'
  id: Scalars['ID']
  images?: Maybe<ImageType>
  language: Language
  soundURL?: Maybe<Scalars['String']>
  text: Scalars['String']
  videoURL?: Maybe<Scalars['String']>
}

export type QuizGameQuestionResult = {
  __typename?: 'QuizGameQuestionResult'
  answerDistribution: Array<Scalars['Int']>
  correctResponseId: Scalars['ID']
}

export enum QuizGameState {
  ASSIGNMENT_COUNT_DOWN = 'ASSIGNMENT_COUNT_DOWN',
  ASSIGNMENT_FINISHED = 'ASSIGNMENT_FINISHED',
  ASSIGNMENT_IN_PROGRESS = 'ASSIGNMENT_IN_PROGRESS',
  EXITED = 'EXITED',
  FINISHED = 'FINISHED',
  WAITING_FOR_PLAYERS = 'WAITING_FOR_PLAYERS'
}

export type ReleaseNotes = {
  __typename?: 'ReleaseNotes'
  id: Scalars['ID']
  url: Scalars['String']
}

export type ReleaseNotesInput = {
  language: Language
  userRole: UserRole
}

export type ResponseInput = {
  imageURLOfResponse?: InputMaybe<Scalars['String']>
  pronunciation?: InputMaybe<PronunciationInput>
  soundURLOfResponse?: InputMaybe<Scalars['String']>
  sourceLanguage: Language
  targetLanguage: Language
  textOfResponse: Scalars['String']
}

export type ResponseInteraction = {
  __typename?: 'ResponseInteraction'
  activityResult: ActivityResult
  assignedLearningFeatureId: Scalars['ID']
  correctResponse: LearningFeatureNew
  correctSpelling?: Maybe<Array<CorrectSpelling>>
  isSkipped: Scalars['Boolean']
  learningExecutionProgress: Scalars['Int']
  learningExecutionState: LearningExecutionState
  learningFeatureState: LearningFeatureState
  newPoints: Scalars['Int']
  reactionTime: Scalars['Int']
  successMeasure: Scalars['Int']
  totalPoints: Scalars['Int']
  tryCount: Scalars['Int']
}

export type ResponseInteractionReactionTimeArgs = {
  unit?: InputMaybe<TimeUnits>
}

export type SearchDictionaryFilterInput = {
  expressionType?: InputMaybe<Array<LearningFeatureType>>
  isUserOwned?: InputMaybe<Scalars['Boolean']>
  level?: InputMaybe<Array<CEFRLevel>>
}

export type SearchDictionaryLearningFeatureInput = {
  sourceLanguage: Language
  targetLanguage: Language
  text: Scalars['String']
}

export type SearchLearningFeatureInput = {
  size?: InputMaybe<Scalars['Int']>
  sourceLanguage: Language
  targetLanguage: Language
  text: Scalars['String']
}

export enum Skill {
  READING = 'READING',
  SPEAKING = 'SPEAKING',
  WRITING = 'WRITING'
}

export enum SpellingCorrectionType {
  ADDED = 'ADDED',
  CORRECT = 'CORRECT',
  INCORRECT = 'INCORRECT',
  SWAPPED = 'SWAPPED'
}

export type StartLearningPathNodeExecutionInput = {
  id: Scalars['ID']
  type: LearningPathNodeType
}

export type Student = {
  __typename?: 'Student'
  applicationSettings: ApplicationSettings
  /** @deprecated Use studentDetail.classroom instead */
  classroom?: Maybe<Classroom>
  classrooms: Array<Classroom>
  studentDetail: StudentDetail
  studentLastLogin?: Maybe<Scalars['DateTime']>
  userProfile: UserProfile
}

export type StudentDetail = {
  __typename?: 'StudentDetail'
  exerciseExecutions: Array<LearningExecution>
  listeningProgress: Scalars['Int']
  notes?: Maybe<Scalars['String']>
  overallSuccessMeasure: Scalars['Int']
  readingProgress: Scalars['Int']
  speakingProgress: Scalars['Int']
  testExecutions: Array<LearningExecution>
  writingProgress: Scalars['Int']
}

export type StudentInfo = {
  __typename?: 'StudentInfo'
  allLearning: Array<Learning>
  selectedLearning?: Maybe<Learning>
  student: Student
}

export type StudentInteractionInput = {
  assignedLearningFeatureId: Scalars['ID']
  flashCardAssignmentType?: InputMaybe<FlashCardAssignmentType>
  flashCardFullScreenType?: InputMaybe<FullScreenFlashCardType>
  flashCardId: Scalars['ID']
  flashCardResponseType?: InputMaybe<FlashCardResponseType>
  isSkipped: Scalars['Boolean']
  learningExecutionId: Scalars['ID']
  reactionTimeMilliseconds: Scalars['Int']
  responseInput: ResponseInput
  totalTimeMilliseconds: Scalars['Int']
  tryCount: Scalars['Int']
}

export enum StudentLibraryImageBackground {
  BEIGE = 'BEIGE',
  BLUE = 'BLUE',
  GREEN = 'GREEN',
  LIGHT_BLUE = 'LIGHT_BLUE',
  MINT = 'MINT',
  ORANGE = 'ORANGE',
  PINK = 'PINK',
  PURPLE = 'PURPLE',
  SALMON = 'SALMON',
  YELLOW = 'YELLOW'
}

export type StudentLibraryOverview = {
  __typename?: 'StudentLibraryOverview'
  inProgress: Array<StudentLibrarySubPackageOverview>
  packages: Array<StudentLibraryPackageOverview>
}

export type StudentLibraryPackageOverview = {
  __typename?: 'StudentLibraryPackageOverview'
  id: Scalars['ID']
  name: Scalars['String']
  subPackages: Array<Maybe<StudentLibrarySubPackageOverview>>
}

export enum StudentLibraryRankLevel {
  LEGEND = 'LEGEND',
  PRO = 'PRO',
  ROOKIE = 'ROOKIE'
}

export type StudentLibrarySubPackage = {
  __typename?: 'StudentLibrarySubPackage'
  id: Scalars['ID']
  image: ImageType
  imageBackground: StudentLibraryImageBackground
  isFavorite: Scalars['Boolean']
  levels: Array<StudentLibrarySubPackageLevel>
  name: Scalars['String']
  packageId: Scalars['ID']
  packageName: Scalars['String']
}

export enum StudentLibrarySubPackageCategory {
  QUESTIONS = 'QUESTIONS',
  SENTENCES = 'SENTENCES',
  VOCABULARY = 'VOCABULARY'
}

export type StudentLibrarySubPackageInput = {
  isFavorite: Scalars['Boolean']
}

export type StudentLibrarySubPackageLevel = {
  __typename?: 'StudentLibrarySubPackageLevel'
  answers?: Maybe<Array<FlashCardAnswer>>
  category: Array<StudentLibrarySubPackageCategory>
  estimatedDurationSeconds: Scalars['Int']
  features: Array<LearningFeatureNew>
  finishedDurationSeconds?: Maybe<Scalars['Int']>
  isEnabled: Scalars['Boolean']
  isStarted: Scalars['Boolean']
  level: StudentLibraryRankLevel
  progress?: Maybe<Scalars['Int']>
  remainingDurationSeconds?: Maybe<Scalars['Int']>
  subcategory: Array<Scalars['String']>
  successMeasure?: Maybe<Scalars['Int']>
}

export type StudentLibrarySubPackageOverview = {
  __typename?: 'StudentLibrarySubPackageOverview'
  currentProgress?: Maybe<Scalars['Int']>
  currentSuccessMeasure?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  image: ImageType
  imageBackground: StudentLibraryImageBackground
  isEnabled: Scalars['Boolean']
  isFavorite: Scalars['Boolean']
  isStarted: Scalars['Boolean']
  name: Scalars['String']
}

export type StudentPerformanceReport = {
  __typename?: 'StudentPerformanceReport'
  homeworks?: Maybe<LearningExecutionReport>
  quizzes?: Maybe<LearningExecutionReport>
  selfStudies?: Maybe<LearningExecutionReport>
  student: Student
  studentSkillProgress: StudentSkillProgress
  summary: Scalars['String']
  tests?: Maybe<LearningExecutionReport>
}

export type StudentPerformanceReportEnvelope = {
  __typename?: 'StudentPerformanceReportEnvelope'
  monthly: StudentPerformanceReport
  weekly: StudentPerformanceReport
}

export type StudentRank = {
  __typename?: 'StudentRank'
  currentStudent: Scalars['Boolean']
  id: Scalars['ID']
  points: Scalars['Int']
  student: Student
}

export type StudentSkillProgress = {
  __typename?: 'StudentSkillProgress'
  listeningProgress: Scalars['Int']
  overallProgress: Scalars['Int']
  readingProgress: Scalars['Int']
  speakingProgress: Scalars['Int']
  writingProgress: Scalars['Int']
}

export type StudentStatistics = {
  __typename?: 'StudentStatistics'
  id: Scalars['ID']
  studentSkillProgress: StudentSkillProgress
}

export enum Subject {
  CZECH = 'CZECH',
  ENGLISH = 'ENGLISH',
  FRENCH = 'FRENCH',
  GERMAN = 'GERMAN',
  ITALIAN = 'ITALIAN',
  SPANISH = 'SPANISH'
}

export type Subscription = {
  __typename?: 'Subscription'
  quizGame: QuizGame
  quizGameClient: QuizGame
}

export type SubscriptionQuizGameArgs = {
  quizId: Scalars['ID']
}

export type SubscriptionQuizGameClientArgs = {
  qrCode: Scalars['String']
}

export type SupportedVersion = {
  __typename?: 'SupportedVersion'
  lastSupportedVersion?: Maybe<Version>
  recommendedVersion?: Maybe<Version>
}

export type Team = {
  __typename?: 'Team'
  imageURL: Scalars['String']
  name: Scalars['String']
}

export enum TimeUnits {
  DAY = 'DAY',
  HOUR = 'HOUR',
  MILLISECOND = 'MILLISECOND',
  MINUTE = 'MINUTE',
  MONTH = 'MONTH',
  /** Determining time period from the beginning of the recording history. */
  OVERALL = 'OVERALL',
  SECOND = 'SECOND',
  WEEK = 'WEEK',
  YEAR = 'YEAR'
}

export enum TimeView {
  MONTH = 'MONTH',
  WEEK = 'WEEK'
}

export type TutorialProgress = {
  __typename?: 'TutorialProgress'
  isSkipped: Scalars['Boolean']
  tutorialStepCompleted: Scalars['Int']
}

export type UserAnswer = {
  __typename?: 'UserAnswer'
  assignmentText: Scalars['String']
  correctAnswer: Scalars['String']
  result: ActivityResult
  userAnswer?: Maybe<Scalars['String']>
}

export type UserBulkRegistration = {
  email: Scalars['String']
  name: Scalars['String']
}

export type UserBulkRegistrationEnvelopeResponse = {
  __typename?: 'UserBulkRegistrationEnvelopeResponse'
  initialCredentialsBase64?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
  users: Array<UserBulkRegistrationResponse>
}

export type UserBulkRegistrationError = {
  __typename?: 'UserBulkRegistrationError'
  code: Scalars['String']
  description: Scalars['String']
  field: Scalars['String']
}

export type UserBulkRegistrationResponse = {
  __typename?: 'UserBulkRegistrationResponse'
  email: Scalars['String']
  errors: Array<UserBulkRegistrationError>
  firstName: Scalars['String']
  isAlreadyRegistered: Scalars['Boolean']
  isValid: Scalars['Boolean']
  lastName: Scalars['String']
  originalName: Scalars['String']
}

export type UserLearningFeatureInput = {
  level?: InputMaybe<CEFRLevel>
  sourceLanguage: Language
  sourceLanguageText: Scalars['String']
  targetLanguage: Language
  targetLanguageText: Scalars['String']
  type?: InputMaybe<LearningFeatureType>
}

export type UserProfile = {
  __typename?: 'UserProfile'
  birthday?: Maybe<Scalars['Date']>
  email?: Maybe<Scalars['String']>
  firstName: Scalars['String']
  id: Scalars['ID']
  lastName: Scalars['String']
  pictureUrl?: Maybe<Scalars['String']>
  username: Scalars['String']
}

export type UserProfileInput = {
  birthday?: InputMaybe<Scalars['Date']>
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  pictureId?: InputMaybe<Scalars['ID']>
}

export enum UserRole {
  STUDENT = 'STUDENT',
  TEACHER = 'TEACHER'
}

export enum UserStatisticsSkills {
  LISTENING = 'LISTENING',
  READING = 'READING',
  SPEAKING = 'SPEAKING',
  WRITING = 'WRITING'
}

export type Version = {
  __typename?: 'Version'
  lastSupportDate?: Maybe<Scalars['Date']>
  version: Scalars['String']
}

export type WorksheetAllowedType = {
  __typename?: 'WorksheetAllowedType'
  allowedLexicalTypes: Array<GQLWorksheetLexicalType>
  worksheetType: GQLWorksheetType
}

export type WorksheetPdf = {
  __typename?: 'WorksheetPdf'
  contentBase64: Scalars['String']
  fileName: Scalars['String']
  worksheetLexicalType: GQLWorksheetLexicalType
  worksheetType: GQLWorksheetType
}

export type GetCodeExpirationQueryVariables = Exact<{
  classroomLoginCode: Scalars['String']
}>

export type GetCodeExpirationQuery = {
  __typename?: 'Query'
  students: {
    __typename?: 'ClassroomLogin'
    classroomLoginCode: { __typename?: 'ClassroomLoginCode'; classroomLoginCode: string; expirationTime: string }
  }
}

export type GetStudentAppSupportedVersionQueryVariables = Exact<{ [key: string]: never }>

export type GetStudentAppSupportedVersionQuery = {
  __typename?: 'Query'
  studentAppSupportedVersion: {
    __typename?: 'SupportedVersion'
    lastSupportedVersion?: { __typename?: 'Version'; version: string; lastSupportDate?: string | null } | null
    recommendedVersion?: { __typename?: 'Version'; version: string; lastSupportDate?: string | null } | null
  }
}

export type GetStudentsQueryVariables = Exact<{
  classroomLoginCode: Scalars['String']
  withStudents?: InputMaybe<Scalars['Boolean']>
}>

export type GetStudentsQuery = {
  __typename?: 'Query'
  students: {
    __typename?: 'ClassroomLogin'
    classroomLoginCode: {
      __typename?: 'ClassroomLoginCode'
      classroomLoginCode: string
      classroomId: string
      expirationTime: string
    }
    students?: Array<{
      __typename?: 'UserProfile'
      id: string
      pictureUrl?: string | null
      firstName: string
      lastName: string
      email?: string | null
    }>
  }
}

export type LoginStudentQueryVariables = Exact<{
  classroomLoginCode: Scalars['String']
  studentId: Scalars['ID']
}>

export type LoginStudentQuery = {
  __typename?: 'Query'
  loginStudent: {
    __typename?: 'LoginToken'
    accessToken?: string | null
    refreshToken?: string | null
    classroomLoginCode?: {
      __typename?: 'ClassroomLoginCode'
      classroomLoginCode: string
      expirationTime: string
    } | null
  }
}

export type ConfirmReleaseNotesMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type ConfirmReleaseNotesMutation = { __typename?: 'Mutation'; confirmReleaseNotes: boolean }

export type GetReleaseNotesQueryVariables = Exact<{
  input: ReleaseNotesInput
  page: PageableInput
}>

export type GetReleaseNotesQuery = {
  __typename?: 'Query'
  releaseNotes: Array<{ __typename?: 'ReleaseNotes'; id: string; url: string }>
}

export type GetLearningPathQueryVariables = Exact<{
  classroomId: Scalars['ID']
  learningPlanUnitId?: InputMaybe<Scalars['ID']>
}>

export type GetLearningPathQuery = {
  __typename?: 'Query'
  learningPath: {
    __typename?: 'LearningPathResponse'
    studentId: string
    classroomId: string
    currentNode: string
    previousNode?: string | null
    nextNode?: string | null
    nodes: Array<{
      __typename?: 'LearningPathNode'
      id: string
      type: LearningPathNodeType
      isPersistent: boolean
      subject: Subject
      executionId?: string | null
      assignmentIndex?: number | null
      assignmentState: LearningPathNodeState
      assignmentType?: LearningAssignmentType | null
      name: string
      deadline?: string | null
      progress: { __typename?: 'LearningPathNodeProgress'; progress: number; total: number }
    }>
  }
}

export type StartLearningPathNodeExecutionMutationVariables = Exact<{
  input: StartLearningPathNodeExecutionInput
}>

export type StartLearningPathNodeExecutionMutation = { __typename?: 'Mutation'; startLearningPathNodeExecution: string }

export type GetNextFlashCardQueryVariables = Exact<{
  nextFlashCardInput: NextFlashCardInput
}>

export type GetNextFlashCardQuery = {
  __typename?: 'Query'
  nextFlashCard:
    | {
        __typename?: 'ComposedFlashCard'
        flashCardAssignmentType: FlashCardAssignmentType
        flashCardResponseType: FlashCardResponseType
        subjectLanguage: Language
        id: string
        learningExecutionProgress: number
        learningExecutionState: LearningExecutionState
        totalPoints: number
        assignedLearningFeatures: Array<{
          __typename?: 'LearningFeatureNew'
          id: string
          type: LearningFeatureType
          hasBeenSeen: boolean
          sourceLanguage: Language
          sourceLanguageText: string
          sourceSoundUrls: Array<string>
          sourceVideoUrls: Array<string>
          targetLanguage: Language
          targetLanguageText: string
          targetSoundUrls: Array<string>
          targetVideoUrls: Array<string>
          images: Array<{
            __typename?: 'ImageType'
            thumbnail?: string | null
            small?: string | null
            medium?: string | null
            large?: string | null
            default: string
          }>
        }>
        responseLearningFeatures: Array<{
          __typename?: 'LearningFeatureNew'
          id: string
          type: LearningFeatureType
          hasBeenSeen: boolean
          sourceLanguage: Language
          sourceLanguageText: string
          sourceSoundUrls: Array<string>
          sourceVideoUrls: Array<string>
          targetLanguage: Language
          targetLanguageText: string
          targetSoundUrls: Array<string>
          targetVideoUrls: Array<string>
          images: Array<{
            __typename?: 'ImageType'
            thumbnail?: string | null
            small?: string | null
            medium?: string | null
            large?: string | null
            default: string
          }>
        }>
      }
    | {
        __typename?: 'FullScreenFlashCard'
        fullScreenFlashCardType: FullScreenFlashCardType
        subjectLanguage: Language
        id: string
        learningExecutionProgress: number
        learningExecutionState: LearningExecutionState
        totalPoints: number
        assignedLearningFeatures: Array<{
          __typename?: 'LearningFeatureNew'
          id: string
          type: LearningFeatureType
          hasBeenSeen: boolean
          sourceLanguage: Language
          sourceLanguageText: string
          sourceSoundUrls: Array<string>
          sourceVideoUrls: Array<string>
          targetLanguage: Language
          targetLanguageText: string
          targetSoundUrls: Array<string>
          targetVideoUrls: Array<string>
          images: Array<{
            __typename?: 'ImageType'
            thumbnail?: string | null
            small?: string | null
            medium?: string | null
            large?: string | null
            default: string
          }>
        }>
        responseLearningFeatures: Array<{
          __typename?: 'LearningFeatureNew'
          id: string
          type: LearningFeatureType
          hasBeenSeen: boolean
          sourceLanguage: Language
          sourceLanguageText: string
          sourceSoundUrls: Array<string>
          sourceVideoUrls: Array<string>
          targetLanguage: Language
          targetLanguageText: string
          targetSoundUrls: Array<string>
          targetVideoUrls: Array<string>
          images: Array<{
            __typename?: 'ImageType'
            thumbnail?: string | null
            small?: string | null
            medium?: string | null
            large?: string | null
            default: string
          }>
        }>
      }
    | {
        __typename?: 'InfoFlashCard'
        informationType: InformationFlashCardType
        id: string
        learningExecutionProgress: number
        learningExecutionState: LearningExecutionState
        totalPoints: number
      }
}

export type ProcessExecutionExitMutationVariables = Exact<{
  exerciseExitInput: ExecutionExitInput
}>

export type ProcessExecutionExitMutation = { __typename?: 'Mutation'; processExecutionExit: boolean }

export type ProcessStudentInteractionMutationVariables = Exact<{
  studentInteractionInput: StudentInteractionInput
}>

export type ProcessStudentInteractionMutation = {
  __typename?: 'Mutation'
  processStudentInteraction: {
    __typename?: 'ResponseInteraction'
    isSkipped: boolean
    tryCount: number
    reactionTime: number
    learningExecutionProgress: number
    learningExecutionState: LearningExecutionState
    learningFeatureState: LearningFeatureState
    assignedLearningFeatureId: string
    successMeasure: number
    activityResult: ActivityResult
    totalPoints: number
    newPoints: number
    correctResponse: {
      __typename?: 'LearningFeatureNew'
      id: string
      sourceLanguageText: string
      sourceSoundUrls: Array<string>
      sourceVideoUrls: Array<string>
      targetLanguageText: string
      targetSoundUrls: Array<string>
      targetVideoUrls: Array<string>
      images: Array<{
        __typename?: 'ImageType'
        thumbnail?: string | null
        small?: string | null
        medium?: string | null
        large?: string | null
        default: string
      }>
    }
    correctSpelling?: Array<{
      __typename?: 'CorrectSpelling'
      letter: string
      spellingCorrectionType: SpellingCorrectionType
    }> | null
  }
}

export type ReportBugInLearningFeatureMutationVariables = Exact<{
  userId: Scalars['ID']
  learningFeatureId?: InputMaybe<Scalars['ID']>
  flashCardAssignmentType?: InputMaybe<FlashCardAssignmentType>
  flashCardResponseType?: InputMaybe<FlashCardResponseType>
  flashCardFullScreenType?: InputMaybe<FullScreenFlashCardType>
  logMessage?: InputMaybe<Scalars['String']>
  os: Scalars['String']
  appVersion: Scalars['String']
  bugDescription: Scalars['String']
}>

export type ReportBugInLearningFeatureMutation = { __typename?: 'Mutation'; reportBugInLearningFeature: string }

export type ProcessLearningExecutionStatisticsMutationVariables = Exact<{
  learningSessionStatisticsInput: LearningExecutionStatisticsInput
}>

export type ProcessLearningExecutionStatisticsMutation = {
  __typename?: 'Mutation'
  processLearningExecutionStatistics: {
    __typename?: 'LearningExecutionStatistics'
    successMeasure: number
    progress: number
    learningFeatureCount: number
    successfulLearningFeatureCount: number
    totalSkippedCount: number
  }
}

export type StartStudentLibrarySubPackageMutationVariables = Exact<{
  subPackageId: Scalars['ID']
  level: StudentLibraryRankLevel
}>

export type StartStudentLibrarySubPackageMutation = { __typename?: 'Mutation'; startStudentLibrarySubPackage: string }

export type StudentLibraryDetailQueryVariables = Exact<{
  subPackageId: Scalars['ID']
}>

export type StudentLibraryDetailQuery = {
  __typename?: 'Query'
  studentLibraryDetail: {
    __typename?: 'StudentLibrarySubPackage'
    id: string
    name: string
    imageBackground: StudentLibraryImageBackground
    packageId: string
    packageName: string
    isFavorite: boolean
    image: {
      __typename?: 'ImageType'
      default: string
      thumbnail?: string | null
      small?: string | null
      large?: string | null
    }
    levels: Array<{
      __typename?: 'StudentLibrarySubPackageLevel'
      isEnabled: boolean
      isStarted: boolean
      successMeasure?: number | null
      progress?: number | null
      level: StudentLibraryRankLevel
      category: Array<StudentLibrarySubPackageCategory>
      subcategory: Array<string>
      estimatedDurationSeconds: number
      remainingDurationSeconds?: number | null
      finishedDurationSeconds?: number | null
      features: Array<{
        __typename?: 'LearningFeatureNew'
        sourceLanguageText: string
        targetLanguageText: string
        sourceSoundUrls: Array<string>
        targetSoundUrls: Array<string>
        images: Array<{ __typename?: 'ImageType'; small?: string | null }>
      }>
      answers?: Array<{
        __typename?: 'FlashCardAnswer'
        flashCardAssignmentType?: FlashCardAssignmentType | null
        flashCardResponseType?: FlashCardResponseType | null
        flashCardFullScreenType?: FullScreenFlashCardType | null
        skipCount: number
        tryCount: number
        points: number
        userAnswers: Array<{
          __typename?: 'UserAnswer'
          assignmentText: string
          userAnswer?: string | null
          correctAnswer: string
          result: ActivityResult
        }>
      }> | null
    }>
  }
}

export type StudentLibraryOverviewQueryVariables = Exact<{
  subject?: InputMaybe<Subject>
}>

export type StudentLibraryOverviewQuery = {
  __typename?: 'Query'
  studentLibraryOverview: {
    __typename?: 'StudentLibraryOverview'
    inProgress: Array<{
      __typename?: 'StudentLibrarySubPackageOverview'
      id: string
      name: string
      imageBackground: StudentLibraryImageBackground
      isEnabled: boolean
      isFavorite: boolean
      isStarted: boolean
      currentProgress?: number | null
      currentSuccessMeasure?: number | null
      image: {
        __typename?: 'ImageType'
        default: string
        thumbnail?: string | null
        small?: string | null
        large?: string | null
      }
    }>
    packages: Array<{
      __typename?: 'StudentLibraryPackageOverview'
      id: string
      name: string
      subPackages: Array<{
        __typename?: 'StudentLibrarySubPackageOverview'
        id: string
        name: string
        imageBackground: StudentLibraryImageBackground
        isEnabled: boolean
        isFavorite: boolean
        isStarted: boolean
        currentProgress?: number | null
        currentSuccessMeasure?: number | null
        image: {
          __typename?: 'ImageType'
          default: string
          thumbnail?: string | null
          small?: string | null
          large?: string | null
        }
      } | null>
    }>
  }
}

export type UpdateIsFavoriteFlagMutationVariables = Exact<{
  subPackageId: Scalars['ID']
  input: StudentLibrarySubPackageInput
}>

export type UpdateIsFavoriteFlagMutation = { __typename?: 'Mutation'; updateStudentLibrarySubPackage: boolean }

export type GetStudentRanksQueryVariables = Exact<{
  classroomId: Scalars['ID']
}>

export type GetStudentRanksQuery = {
  __typename?: 'Query'
  weekRanks: Array<{
    __typename: 'StudentRank'
    id: string
    points: number
    currentStudent: boolean
    student: {
      __typename?: 'Student'
      userProfile: {
        __typename?: 'UserProfile'
        id: string
        pictureUrl?: string | null
        firstName: string
        lastName: string
      }
    }
  }>
  monthRanks: Array<{
    __typename: 'StudentRank'
    id: string
    points: number
    currentStudent: boolean
    student: {
      __typename?: 'Student'
      userProfile: {
        __typename?: 'UserProfile'
        id: string
        pictureUrl?: string | null
        firstName: string
        lastName: string
      }
    }
  }>
  overallRanks: Array<{
    __typename: 'StudentRank'
    id: string
    points: number
    currentStudent: boolean
    student: {
      __typename?: 'Student'
      userProfile: {
        __typename?: 'UserProfile'
        id: string
        pictureUrl?: string | null
        firstName: string
        lastName: string
      }
    }
  }>
}

export type AddMeToClassroomMutationVariables = Exact<{
  classroomId: Scalars['ID']
  classroomCode: Scalars['String']
}>

export type AddMeToClassroomMutation = { __typename?: 'Mutation'; addMeToClassroom: boolean }

export type AddUserRoleMutationVariables = Exact<{
  userRole: UserRole
}>

export type AddUserRoleMutation = { __typename?: 'Mutation'; addUserRole: boolean }

export type UpdateAppSettingsMutationVariables = Exact<{
  applicationSettings?: InputMaybe<ApplicationSettingsInput>
}>

export type UpdateAppSettingsMutation = {
  __typename?: 'Mutation'
  updateAppSettings: { __typename?: 'ApplicationSettings'; appLanguage: Language }
}

export type UpdateUserProfileMutationVariables = Exact<{
  userProfile?: InputMaybe<UserProfileInput>
}>

export type UpdateUserProfileMutation = {
  __typename?: 'Mutation'
  updateUserProfile: {
    __typename?: 'UserProfile'
    id: string
    firstName: string
    lastName: string
    birthday?: string | null
    pictureUrl?: string | null
  }
}

export type AnswerQuizQuestionMutationVariables = Exact<{
  quizId: Scalars['ID']
  questionId: Scalars['ID']
  answer: Scalars['Int']
  timestamp?: InputMaybe<Scalars['DateTime']>
}>

export type AnswerQuizQuestionMutation = { __typename?: 'Mutation'; answerQuizQuestion: boolean }

export type GetQuizGameQueryVariables = Exact<{
  quizId: Scalars['ID']
}>

export type GetQuizGameQuery = {
  __typename?: 'Query'
  quizGame: {
    __typename?: 'QuizGame'
    id: string
    name: string
    gameState: QuizGameState
    numOfQuestions: number
    secondsPerQuestion: number
    showQuestionInClient: boolean
    countdownInSeconds: number
    currentQuestion?: {
      __typename?: 'QuizGameQuestion'
      id: string
      index: number
      assignmentType: QuizAssignmentType
      numOfAnswers: number
      hasNext: boolean
      assignment: {
        __typename: 'QuizGameQuestionItem'
        id: string
        soundURL?: string | null
        videoURL?: string | null
        language: Language
        text: string
        images?: { __typename?: 'ImageType'; medium?: string | null; large?: string | null; default: string } | null
      }
      responses: Array<{ __typename: 'QuizGameQuestionItem'; id: string; text: string }>
      questionResult: {
        __typename?: 'QuizGameQuestionResult'
        correctResponseId: string
        answerDistribution: Array<number>
      }
    } | null
    connectedPlayers: Array<{
      __typename?: 'QuizGamePlayer'
      id: string
      lastName: string
      firstName: string
      pictureUrl?: string | null
      newPosition: number
      oldPosition: number
      totalPoints: number
      newPoints: number
    } | null>
  }
}

export type JoinQuizGameMutationVariables = Exact<{
  code: Scalars['String']
}>

export type JoinQuizGameMutation = { __typename?: 'Mutation'; joinQuizGame: { __typename?: 'QuizGame'; id: string } }

export type LeaveQuizGameMutationVariables = Exact<{
  quizId: Scalars['ID']
}>

export type LeaveQuizGameMutation = { __typename?: 'Mutation'; leaveQuizGame: boolean }

export type QuizGameClientSubscriptionVariables = Exact<{
  qrCode: Scalars['String']
}>

export type QuizGameClientSubscription = {
  __typename?: 'Subscription'
  quizGameClient: {
    __typename?: 'QuizGame'
    id: string
    name: string
    gameState: QuizGameState
    numOfQuestions: number
    secondsPerQuestion: number
    showQuestionInClient: boolean
    countdownInSeconds: number
    currentQuestion?: {
      __typename?: 'QuizGameQuestion'
      id: string
      index: number
      assignmentType: QuizAssignmentType
      numOfAnswers: number
      hasNext: boolean
      assignment: {
        __typename?: 'QuizGameQuestionItem'
        id: string
        soundURL?: string | null
        videoURL?: string | null
        language: Language
        text: string
        images?: { __typename?: 'ImageType'; medium?: string | null; large?: string | null; default: string } | null
      }
      responses: Array<{ __typename?: 'QuizGameQuestionItem'; id: string; text: string }>
      questionResult: {
        __typename?: 'QuizGameQuestionResult'
        correctResponseId: string
        answerDistribution: Array<number>
      }
    } | null
    connectedPlayers: Array<{
      __typename?: 'QuizGamePlayer'
      id: string
      lastName: string
      firstName: string
      pictureUrl?: string | null
      newPosition: number
      oldPosition: number
      totalPoints: number
      newPoints: number
    } | null>
  }
}

export type GetActiveTasksQueryVariables = Exact<{
  classroomId?: InputMaybe<Scalars['ID']>
}>

export type GetActiveTasksQuery = {
  __typename?: 'Query'
  tasks: Array<{
    __typename?: 'LearningExecution'
    id: string
    name: string
    progress: number
    successMeasure: number
    state: LearningExecutionState
    remainingTimeSeconds?: number | null
    learningAssignment: {
      __typename?: 'LearningAssignment'
      id: string
      name: string
      skills: Array<Skill>
      endDateAndTime?: string | null
      startDateAndTime: string
      assignedByTeacher?: boolean | null
      learningFeatureCount: number
      imageURL?: string | null
      difficulty: Difficulty
      level: CEFRLevel
      state: LearningAssignmentState
      type: LearningAssignmentType
      subject: Subject
    }
  }>
}

export type GetCompletedLearningExecutionQueryVariables = Exact<{
  learningExecutionId: Scalars['ID']
}>

export type GetCompletedLearningExecutionQuery = {
  __typename?: 'Query'
  completedLearningExecution: {
    __typename?: 'CompletedLearningExecution'
    studyTimeSeconds: number
    totalSkippedCount: number
    learningExecution: {
      __typename?: 'LearningExecution'
      id: string
      name: string
      progress: number
      successMeasure: number
      state: LearningExecutionState
      learningAssignment: {
        __typename?: 'LearningAssignment'
        type: LearningAssignmentType
        endDateAndTime?: string | null
        learningFeatureCount: number
        image?: {
          __typename?: 'ImageType'
          thumbnail?: string | null
          small?: string | null
          medium?: string | null
          large?: string | null
          default: string
        } | null
      }
    }
    answers: Array<{
      __typename?: 'FlashCardAnswer'
      flashCardAssignmentType?: FlashCardAssignmentType | null
      flashCardResponseType?: FlashCardResponseType | null
      flashCardFullScreenType?: FullScreenFlashCardType | null
      skipCount: number
      tryCount: number
      points: number
      userAnswers: Array<{
        __typename?: 'UserAnswer'
        assignmentText: string
        userAnswer?: string | null
        correctAnswer: string
        result: ActivityResult
      }>
    }>
  }
}

export type GetFinishedTasksQueryVariables = Exact<{
  classroomId?: InputMaybe<Scalars['ID']>
  page: PageableInput
}>

export type GetFinishedTasksQuery = {
  __typename?: 'Query'
  tasks: Array<{
    __typename?: 'LearningExecution'
    id: string
    name: string
    progress: number
    successMeasure: number
    state: LearningExecutionState
    remainingTimeSeconds?: number | null
    learningAssignment: {
      __typename?: 'LearningAssignment'
      id: string
      name: string
      skills: Array<Skill>
      endDateAndTime?: string | null
      startDateAndTime: string
      assignedByTeacher?: boolean | null
      learningFeatureCount: number
      imageURL?: string | null
      difficulty: Difficulty
      level: CEFRLevel
      state: LearningAssignmentState
      type: LearningAssignmentType
      subject: Subject
    }
  }>
}

export type GetRevisionLearningExecutionsQueryVariables = Exact<{ [key: string]: never }>

export type GetRevisionLearningExecutionsQuery = {
  __typename?: 'Query'
  revisionLearningExecutions: Array<{
    __typename?: 'LearningExecution'
    id: string
    name: string
    progress: number
    successMeasure: number
    state: LearningExecutionState
    learningAssignment: {
      __typename?: 'LearningAssignment'
      id: string
      name: string
      skills: Array<Skill>
      endDateAndTime?: string | null
      startDateAndTime: string
      assignedByTeacher?: boolean | null
      learningFeatureCount: number
      difficulty: Difficulty
      level: CEFRLevel
      state: LearningAssignmentState
      type: LearningAssignmentType
      image?: {
        __typename?: 'ImageType'
        thumbnail?: string | null
        small?: string | null
        medium?: string | null
        large?: string | null
        default: string
      } | null
    }
  }>
}

export type GetStudentInfoQueryVariables = Exact<{ [key: string]: never }>

export type GetStudentInfoQuery = {
  __typename?: 'Query'
  studentInfo: {
    __typename?: 'StudentInfo'
    student: {
      __typename?: 'Student'
      userProfile: {
        __typename?: 'UserProfile'
        id: string
        pictureUrl?: string | null
        firstName: string
        lastName: string
        username: string
        birthday?: string | null
        email?: string | null
      }
      applicationSettings: { __typename?: 'ApplicationSettings'; appLanguage: Language }
      classrooms: Array<{ __typename?: 'Classroom'; id: string; name: string; subject: Subject }>
    }
    selectedLearning?: { __typename?: 'Learning'; id: string; name: string; subject: Subject } | null
    allLearning: Array<{ __typename?: 'Learning'; id: string; name: string; subject: Subject }>
  }
}

export const GetCodeExpirationDocument = gql`
  query getCodeExpiration($classroomLoginCode: String!) {
    students(classroomLoginCode: $classroomLoginCode) {
      classroomLoginCode {
        classroomLoginCode
        expirationTime
      }
    }
  }
`

/**
 * __useGetCodeExpirationQuery__
 *
 * To run a query within a React component, call `useGetCodeExpirationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCodeExpirationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCodeExpirationQuery({
 *   variables: {
 *      classroomLoginCode: // value for 'classroomLoginCode'
 *   },
 * });
 */
export function useGetCodeExpirationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetCodeExpirationQuery, GetCodeExpirationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetCodeExpirationQuery, GetCodeExpirationQueryVariables>(
    GetCodeExpirationDocument,
    options
  )
}
export function useGetCodeExpirationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCodeExpirationQuery, GetCodeExpirationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetCodeExpirationQuery, GetCodeExpirationQueryVariables>(
    GetCodeExpirationDocument,
    options
  )
}
export type GetCodeExpirationQueryHookResult = ReturnType<typeof useGetCodeExpirationQuery>
export type GetCodeExpirationLazyQueryHookResult = ReturnType<typeof useGetCodeExpirationLazyQuery>
export type GetCodeExpirationQueryResult = Apollo.QueryResult<GetCodeExpirationQuery, GetCodeExpirationQueryVariables>
export const GetStudentAppSupportedVersionDocument = gql`
  query getStudentAppSupportedVersion {
    studentAppSupportedVersion {
      lastSupportedVersion {
        version
        lastSupportDate
      }
      recommendedVersion {
        version
        lastSupportDate
      }
    }
  }
`

/**
 * __useGetStudentAppSupportedVersionQuery__
 *
 * To run a query within a React component, call `useGetStudentAppSupportedVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentAppSupportedVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentAppSupportedVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStudentAppSupportedVersionQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetStudentAppSupportedVersionQuery,
    GetStudentAppSupportedVersionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetStudentAppSupportedVersionQuery, GetStudentAppSupportedVersionQueryVariables>(
    GetStudentAppSupportedVersionDocument,
    options
  )
}
export function useGetStudentAppSupportedVersionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetStudentAppSupportedVersionQuery,
    GetStudentAppSupportedVersionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetStudentAppSupportedVersionQuery, GetStudentAppSupportedVersionQueryVariables>(
    GetStudentAppSupportedVersionDocument,
    options
  )
}
export type GetStudentAppSupportedVersionQueryHookResult = ReturnType<typeof useGetStudentAppSupportedVersionQuery>
export type GetStudentAppSupportedVersionLazyQueryHookResult = ReturnType<
  typeof useGetStudentAppSupportedVersionLazyQuery
>
export type GetStudentAppSupportedVersionQueryResult = Apollo.QueryResult<
  GetStudentAppSupportedVersionQuery,
  GetStudentAppSupportedVersionQueryVariables
>
export const GetStudentsDocument = gql`
  query getStudents($classroomLoginCode: String!, $withStudents: Boolean = true) {
    students(classroomLoginCode: $classroomLoginCode) {
      classroomLoginCode {
        classroomLoginCode
        classroomId
        expirationTime
      }
      students @include(if: $withStudents) {
        id
        pictureUrl
        firstName
        lastName
        email
      }
    }
  }
`

/**
 * __useGetStudentsQuery__
 *
 * To run a query within a React component, call `useGetStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsQuery({
 *   variables: {
 *      classroomLoginCode: // value for 'classroomLoginCode'
 *      withStudents: // value for 'withStudents'
 *   },
 * });
 */
export function useGetStudentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetStudentsQuery, GetStudentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetStudentsQuery, GetStudentsQueryVariables>(GetStudentsDocument, options)
}
export function useGetStudentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStudentsQuery, GetStudentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetStudentsQuery, GetStudentsQueryVariables>(GetStudentsDocument, options)
}
export type GetStudentsQueryHookResult = ReturnType<typeof useGetStudentsQuery>
export type GetStudentsLazyQueryHookResult = ReturnType<typeof useGetStudentsLazyQuery>
export type GetStudentsQueryResult = Apollo.QueryResult<GetStudentsQuery, GetStudentsQueryVariables>
export const LoginStudentDocument = gql`
  query loginStudent($classroomLoginCode: String!, $studentId: ID!) {
    loginStudent(classroomLoginCode: $classroomLoginCode, studentId: $studentId) {
      accessToken
      refreshToken
      classroomLoginCode {
        classroomLoginCode
        expirationTime
      }
    }
  }
`

/**
 * __useLoginStudentQuery__
 *
 * To run a query within a React component, call `useLoginStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginStudentQuery({
 *   variables: {
 *      classroomLoginCode: // value for 'classroomLoginCode'
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useLoginStudentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<LoginStudentQuery, LoginStudentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<LoginStudentQuery, LoginStudentQueryVariables>(LoginStudentDocument, options)
}
export function useLoginStudentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoginStudentQuery, LoginStudentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<LoginStudentQuery, LoginStudentQueryVariables>(LoginStudentDocument, options)
}
export type LoginStudentQueryHookResult = ReturnType<typeof useLoginStudentQuery>
export type LoginStudentLazyQueryHookResult = ReturnType<typeof useLoginStudentLazyQuery>
export type LoginStudentQueryResult = Apollo.QueryResult<LoginStudentQuery, LoginStudentQueryVariables>
export const ConfirmReleaseNotesDocument = gql`
  mutation confirmReleaseNotes($id: ID!) {
    confirmReleaseNotes(id: $id)
  }
`
export type ConfirmReleaseNotesMutationFn = Apollo.MutationFunction<
  ConfirmReleaseNotesMutation,
  ConfirmReleaseNotesMutationVariables
>

/**
 * __useConfirmReleaseNotesMutation__
 *
 * To run a mutation, you first call `useConfirmReleaseNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmReleaseNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmReleaseNotesMutation, { data, loading, error }] = useConfirmReleaseNotesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConfirmReleaseNotesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ConfirmReleaseNotesMutation, ConfirmReleaseNotesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<ConfirmReleaseNotesMutation, ConfirmReleaseNotesMutationVariables>(
    ConfirmReleaseNotesDocument,
    options
  )
}
export type ConfirmReleaseNotesMutationHookResult = ReturnType<typeof useConfirmReleaseNotesMutation>
export type ConfirmReleaseNotesMutationResult = Apollo.MutationResult<ConfirmReleaseNotesMutation>
export type ConfirmReleaseNotesMutationOptions = Apollo.BaseMutationOptions<
  ConfirmReleaseNotesMutation,
  ConfirmReleaseNotesMutationVariables
>
export const GetReleaseNotesDocument = gql`
  query getReleaseNotes($input: ReleaseNotesInput!, $page: PageableInput!) {
    releaseNotes(input: $input, page: $page) {
      id
      url
    }
  }
`

/**
 * __useGetReleaseNotesQuery__
 *
 * To run a query within a React component, call `useGetReleaseNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReleaseNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReleaseNotesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetReleaseNotesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetReleaseNotesQuery, GetReleaseNotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetReleaseNotesQuery, GetReleaseNotesQueryVariables>(
    GetReleaseNotesDocument,
    options
  )
}
export function useGetReleaseNotesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReleaseNotesQuery, GetReleaseNotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetReleaseNotesQuery, GetReleaseNotesQueryVariables>(
    GetReleaseNotesDocument,
    options
  )
}
export type GetReleaseNotesQueryHookResult = ReturnType<typeof useGetReleaseNotesQuery>
export type GetReleaseNotesLazyQueryHookResult = ReturnType<typeof useGetReleaseNotesLazyQuery>
export type GetReleaseNotesQueryResult = Apollo.QueryResult<GetReleaseNotesQuery, GetReleaseNotesQueryVariables>
export const GetLearningPathDocument = gql`
  query getLearningPath($classroomId: ID!, $learningPlanUnitId: ID) {
    learningPath(classroomId: $classroomId, learningPlanUnitId: $learningPlanUnitId) {
      studentId
      classroomId
      currentNode
      previousNode
      nextNode
      nodes {
        id
        type
        isPersistent
        subject
        executionId
        assignmentIndex
        assignmentState
        assignmentType
        name
        deadline
        progress {
          progress
          total
        }
      }
    }
  }
`

/**
 * __useGetLearningPathQuery__
 *
 * To run a query within a React component, call `useGetLearningPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLearningPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearningPathQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *      learningPlanUnitId: // value for 'learningPlanUnitId'
 *   },
 * });
 */
export function useGetLearningPathQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetLearningPathQuery, GetLearningPathQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetLearningPathQuery, GetLearningPathQueryVariables>(
    GetLearningPathDocument,
    options
  )
}
export function useGetLearningPathLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLearningPathQuery, GetLearningPathQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetLearningPathQuery, GetLearningPathQueryVariables>(
    GetLearningPathDocument,
    options
  )
}
export type GetLearningPathQueryHookResult = ReturnType<typeof useGetLearningPathQuery>
export type GetLearningPathLazyQueryHookResult = ReturnType<typeof useGetLearningPathLazyQuery>
export type GetLearningPathQueryResult = Apollo.QueryResult<GetLearningPathQuery, GetLearningPathQueryVariables>
export const StartLearningPathNodeExecutionDocument = gql`
  mutation startLearningPathNodeExecution($input: StartLearningPathNodeExecutionInput!) {
    startLearningPathNodeExecution(input: $input)
  }
`
export type StartLearningPathNodeExecutionMutationFn = Apollo.MutationFunction<
  StartLearningPathNodeExecutionMutation,
  StartLearningPathNodeExecutionMutationVariables
>

/**
 * __useStartLearningPathNodeExecutionMutation__
 *
 * To run a mutation, you first call `useStartLearningPathNodeExecutionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartLearningPathNodeExecutionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startLearningPathNodeExecutionMutation, { data, loading, error }] = useStartLearningPathNodeExecutionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartLearningPathNodeExecutionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    StartLearningPathNodeExecutionMutation,
    StartLearningPathNodeExecutionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    StartLearningPathNodeExecutionMutation,
    StartLearningPathNodeExecutionMutationVariables
  >(StartLearningPathNodeExecutionDocument, options)
}
export type StartLearningPathNodeExecutionMutationHookResult = ReturnType<
  typeof useStartLearningPathNodeExecutionMutation
>
export type StartLearningPathNodeExecutionMutationResult = Apollo.MutationResult<StartLearningPathNodeExecutionMutation>
export type StartLearningPathNodeExecutionMutationOptions = Apollo.BaseMutationOptions<
  StartLearningPathNodeExecutionMutation,
  StartLearningPathNodeExecutionMutationVariables
>
export const GetNextFlashCardDocument = gql`
  query getNextFlashCard($nextFlashCardInput: NextFlashCardInput!) {
    nextFlashCard(nextFlashCardInput: $nextFlashCardInput) {
      id
      learningExecutionProgress
      learningExecutionState
      totalPoints
      ... on InfoFlashCard {
        informationType
      }
      ... on FullScreenFlashCard {
        fullScreenFlashCardType
        subjectLanguage
        assignedLearningFeatures {
          id
          type
          hasBeenSeen
          images {
            thumbnail
            small
            medium
            large
            default
          }
          sourceLanguage
          sourceLanguageText
          sourceSoundUrls
          sourceVideoUrls
          targetLanguage
          targetLanguageText
          targetSoundUrls
          targetVideoUrls
        }
        responseLearningFeatures {
          id
          type
          hasBeenSeen
          images {
            thumbnail
            small
            medium
            large
            default
          }
          sourceLanguage
          sourceLanguageText
          sourceSoundUrls
          sourceVideoUrls
          targetLanguage
          targetLanguageText
          targetSoundUrls
          targetVideoUrls
        }
      }
      ... on ComposedFlashCard {
        flashCardAssignmentType
        flashCardResponseType
        subjectLanguage
        assignedLearningFeatures {
          id
          type
          hasBeenSeen
          images {
            thumbnail
            small
            medium
            large
            default
          }
          sourceLanguage
          sourceLanguageText
          sourceSoundUrls
          sourceVideoUrls
          targetLanguage
          targetLanguageText
          targetSoundUrls
          targetVideoUrls
        }
        responseLearningFeatures {
          id
          type
          hasBeenSeen
          images {
            thumbnail
            small
            medium
            large
            default
          }
          sourceLanguage
          sourceLanguageText
          sourceSoundUrls
          sourceVideoUrls
          targetLanguage
          targetLanguageText
          targetSoundUrls
          targetVideoUrls
        }
      }
    }
  }
`

/**
 * __useGetNextFlashCardQuery__
 *
 * To run a query within a React component, call `useGetNextFlashCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextFlashCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextFlashCardQuery({
 *   variables: {
 *      nextFlashCardInput: // value for 'nextFlashCardInput'
 *   },
 * });
 */
export function useGetNextFlashCardQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetNextFlashCardQuery, GetNextFlashCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetNextFlashCardQuery, GetNextFlashCardQueryVariables>(
    GetNextFlashCardDocument,
    options
  )
}
export function useGetNextFlashCardLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNextFlashCardQuery, GetNextFlashCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetNextFlashCardQuery, GetNextFlashCardQueryVariables>(
    GetNextFlashCardDocument,
    options
  )
}
export type GetNextFlashCardQueryHookResult = ReturnType<typeof useGetNextFlashCardQuery>
export type GetNextFlashCardLazyQueryHookResult = ReturnType<typeof useGetNextFlashCardLazyQuery>
export type GetNextFlashCardQueryResult = Apollo.QueryResult<GetNextFlashCardQuery, GetNextFlashCardQueryVariables>
export const ProcessExecutionExitDocument = gql`
  mutation processExecutionExit($exerciseExitInput: ExecutionExitInput!) {
    processExecutionExit(exerciseExitInput: $exerciseExitInput)
  }
`
export type ProcessExecutionExitMutationFn = Apollo.MutationFunction<
  ProcessExecutionExitMutation,
  ProcessExecutionExitMutationVariables
>

/**
 * __useProcessExecutionExitMutation__
 *
 * To run a mutation, you first call `useProcessExecutionExitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessExecutionExitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processExecutionExitMutation, { data, loading, error }] = useProcessExecutionExitMutation({
 *   variables: {
 *      exerciseExitInput: // value for 'exerciseExitInput'
 *   },
 * });
 */
export function useProcessExecutionExitMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ProcessExecutionExitMutation,
    ProcessExecutionExitMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<ProcessExecutionExitMutation, ProcessExecutionExitMutationVariables>(
    ProcessExecutionExitDocument,
    options
  )
}
export type ProcessExecutionExitMutationHookResult = ReturnType<typeof useProcessExecutionExitMutation>
export type ProcessExecutionExitMutationResult = Apollo.MutationResult<ProcessExecutionExitMutation>
export type ProcessExecutionExitMutationOptions = Apollo.BaseMutationOptions<
  ProcessExecutionExitMutation,
  ProcessExecutionExitMutationVariables
>
export const ProcessStudentInteractionDocument = gql`
  mutation processStudentInteraction($studentInteractionInput: StudentInteractionInput!) {
    processStudentInteraction(studentInteractionInput: $studentInteractionInput) {
      isSkipped
      tryCount
      reactionTime
      learningExecutionProgress
      learningExecutionState
      learningFeatureState
      assignedLearningFeatureId
      successMeasure
      activityResult
      totalPoints
      newPoints
      correctResponse {
        id
        images {
          thumbnail
          small
          medium
          large
          default
        }
        sourceLanguageText
        sourceSoundUrls
        sourceVideoUrls
        targetLanguageText
        targetSoundUrls
        targetVideoUrls
      }
      correctSpelling {
        letter
        spellingCorrectionType
      }
    }
  }
`
export type ProcessStudentInteractionMutationFn = Apollo.MutationFunction<
  ProcessStudentInteractionMutation,
  ProcessStudentInteractionMutationVariables
>

/**
 * __useProcessStudentInteractionMutation__
 *
 * To run a mutation, you first call `useProcessStudentInteractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessStudentInteractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processStudentInteractionMutation, { data, loading, error }] = useProcessStudentInteractionMutation({
 *   variables: {
 *      studentInteractionInput: // value for 'studentInteractionInput'
 *   },
 * });
 */
export function useProcessStudentInteractionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ProcessStudentInteractionMutation,
    ProcessStudentInteractionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<ProcessStudentInteractionMutation, ProcessStudentInteractionMutationVariables>(
    ProcessStudentInteractionDocument,
    options
  )
}
export type ProcessStudentInteractionMutationHookResult = ReturnType<typeof useProcessStudentInteractionMutation>
export type ProcessStudentInteractionMutationResult = Apollo.MutationResult<ProcessStudentInteractionMutation>
export type ProcessStudentInteractionMutationOptions = Apollo.BaseMutationOptions<
  ProcessStudentInteractionMutation,
  ProcessStudentInteractionMutationVariables
>
export const ReportBugInLearningFeatureDocument = gql`
  mutation reportBugInLearningFeature(
    $userId: ID!
    $learningFeatureId: ID
    $flashCardAssignmentType: FlashCardAssignmentType
    $flashCardResponseType: FlashCardResponseType
    $flashCardFullScreenType: FullScreenFlashCardType
    $logMessage: String
    $os: String!
    $appVersion: String!
    $bugDescription: String!
  ) {
    reportBugInLearningFeature(
      userId: $userId
      learningFeatureId: $learningFeatureId
      flashCardAssignmentType: $flashCardAssignmentType
      flashCardResponseType: $flashCardResponseType
      flashCardFullScreenType: $flashCardFullScreenType
      logMessage: $logMessage
      os: $os
      appVersion: $appVersion
      bugDescription: $bugDescription
    )
  }
`
export type ReportBugInLearningFeatureMutationFn = Apollo.MutationFunction<
  ReportBugInLearningFeatureMutation,
  ReportBugInLearningFeatureMutationVariables
>

/**
 * __useReportBugInLearningFeatureMutation__
 *
 * To run a mutation, you first call `useReportBugInLearningFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportBugInLearningFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportBugInLearningFeatureMutation, { data, loading, error }] = useReportBugInLearningFeatureMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      learningFeatureId: // value for 'learningFeatureId'
 *      flashCardAssignmentType: // value for 'flashCardAssignmentType'
 *      flashCardResponseType: // value for 'flashCardResponseType'
 *      flashCardFullScreenType: // value for 'flashCardFullScreenType'
 *      logMessage: // value for 'logMessage'
 *      os: // value for 'os'
 *      appVersion: // value for 'appVersion'
 *      bugDescription: // value for 'bugDescription'
 *   },
 * });
 */
export function useReportBugInLearningFeatureMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ReportBugInLearningFeatureMutation,
    ReportBugInLearningFeatureMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<ReportBugInLearningFeatureMutation, ReportBugInLearningFeatureMutationVariables>(
    ReportBugInLearningFeatureDocument,
    options
  )
}
export type ReportBugInLearningFeatureMutationHookResult = ReturnType<typeof useReportBugInLearningFeatureMutation>
export type ReportBugInLearningFeatureMutationResult = Apollo.MutationResult<ReportBugInLearningFeatureMutation>
export type ReportBugInLearningFeatureMutationOptions = Apollo.BaseMutationOptions<
  ReportBugInLearningFeatureMutation,
  ReportBugInLearningFeatureMutationVariables
>
export const ProcessLearningExecutionStatisticsDocument = gql`
  mutation processLearningExecutionStatistics($learningSessionStatisticsInput: LearningExecutionStatisticsInput!) {
    processLearningExecutionStatistics(learningExecutionStatisticsInput: $learningSessionStatisticsInput) {
      successMeasure
      progress
      learningFeatureCount
      successfulLearningFeatureCount
      totalSkippedCount
    }
  }
`
export type ProcessLearningExecutionStatisticsMutationFn = Apollo.MutationFunction<
  ProcessLearningExecutionStatisticsMutation,
  ProcessLearningExecutionStatisticsMutationVariables
>

/**
 * __useProcessLearningExecutionStatisticsMutation__
 *
 * To run a mutation, you first call `useProcessLearningExecutionStatisticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessLearningExecutionStatisticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processLearningExecutionStatisticsMutation, { data, loading, error }] = useProcessLearningExecutionStatisticsMutation({
 *   variables: {
 *      learningSessionStatisticsInput: // value for 'learningSessionStatisticsInput'
 *   },
 * });
 */
export function useProcessLearningExecutionStatisticsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ProcessLearningExecutionStatisticsMutation,
    ProcessLearningExecutionStatisticsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    ProcessLearningExecutionStatisticsMutation,
    ProcessLearningExecutionStatisticsMutationVariables
  >(ProcessLearningExecutionStatisticsDocument, options)
}
export type ProcessLearningExecutionStatisticsMutationHookResult = ReturnType<
  typeof useProcessLearningExecutionStatisticsMutation
>
export type ProcessLearningExecutionStatisticsMutationResult =
  Apollo.MutationResult<ProcessLearningExecutionStatisticsMutation>
export type ProcessLearningExecutionStatisticsMutationOptions = Apollo.BaseMutationOptions<
  ProcessLearningExecutionStatisticsMutation,
  ProcessLearningExecutionStatisticsMutationVariables
>
export const StartStudentLibrarySubPackageDocument = gql`
  mutation startStudentLibrarySubPackage($subPackageId: ID!, $level: StudentLibraryRankLevel!) {
    startStudentLibrarySubPackage(subPackageId: $subPackageId, level: $level)
  }
`
export type StartStudentLibrarySubPackageMutationFn = Apollo.MutationFunction<
  StartStudentLibrarySubPackageMutation,
  StartStudentLibrarySubPackageMutationVariables
>

/**
 * __useStartStudentLibrarySubPackageMutation__
 *
 * To run a mutation, you first call `useStartStudentLibrarySubPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartStudentLibrarySubPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startStudentLibrarySubPackageMutation, { data, loading, error }] = useStartStudentLibrarySubPackageMutation({
 *   variables: {
 *      subPackageId: // value for 'subPackageId'
 *      level: // value for 'level'
 *   },
 * });
 */
export function useStartStudentLibrarySubPackageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    StartStudentLibrarySubPackageMutation,
    StartStudentLibrarySubPackageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<
    StartStudentLibrarySubPackageMutation,
    StartStudentLibrarySubPackageMutationVariables
  >(StartStudentLibrarySubPackageDocument, options)
}
export type StartStudentLibrarySubPackageMutationHookResult = ReturnType<
  typeof useStartStudentLibrarySubPackageMutation
>
export type StartStudentLibrarySubPackageMutationResult = Apollo.MutationResult<StartStudentLibrarySubPackageMutation>
export type StartStudentLibrarySubPackageMutationOptions = Apollo.BaseMutationOptions<
  StartStudentLibrarySubPackageMutation,
  StartStudentLibrarySubPackageMutationVariables
>
export const StudentLibraryDetailDocument = gql`
  query studentLibraryDetail($subPackageId: ID!) {
    studentLibraryDetail(subPackageId: $subPackageId) {
      id
      name
      image {
        default
        thumbnail
        small
        large
      }
      imageBackground
      packageId
      packageName
      isFavorite
      levels {
        isEnabled
        isStarted
        successMeasure
        progress
        level
        category
        subcategory
        estimatedDurationSeconds
        remainingDurationSeconds
        finishedDurationSeconds
        features {
          sourceLanguageText
          targetLanguageText
          sourceSoundUrls
          targetSoundUrls
          images {
            small
          }
        }
        answers {
          flashCardAssignmentType
          flashCardResponseType
          flashCardFullScreenType
          userAnswers {
            assignmentText
            userAnswer
            correctAnswer
            result
          }
          skipCount
          tryCount
          points
        }
      }
    }
  }
`

/**
 * __useStudentLibraryDetailQuery__
 *
 * To run a query within a React component, call `useStudentLibraryDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentLibraryDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentLibraryDetailQuery({
 *   variables: {
 *      subPackageId: // value for 'subPackageId'
 *   },
 * });
 */
export function useStudentLibraryDetailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<StudentLibraryDetailQuery, StudentLibraryDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<StudentLibraryDetailQuery, StudentLibraryDetailQueryVariables>(
    StudentLibraryDetailDocument,
    options
  )
}
export function useStudentLibraryDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudentLibraryDetailQuery, StudentLibraryDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<StudentLibraryDetailQuery, StudentLibraryDetailQueryVariables>(
    StudentLibraryDetailDocument,
    options
  )
}
export type StudentLibraryDetailQueryHookResult = ReturnType<typeof useStudentLibraryDetailQuery>
export type StudentLibraryDetailLazyQueryHookResult = ReturnType<typeof useStudentLibraryDetailLazyQuery>
export type StudentLibraryDetailQueryResult = Apollo.QueryResult<
  StudentLibraryDetailQuery,
  StudentLibraryDetailQueryVariables
>
export const StudentLibraryOverviewDocument = gql`
  query studentLibraryOverview($subject: Subject) {
    studentLibraryOverview(subject: $subject) {
      inProgress {
        id
        name
        image {
          default
          thumbnail
          small
          large
        }
        imageBackground
        isEnabled
        isFavorite
        isStarted
        currentProgress
        currentSuccessMeasure
      }
      packages {
        id
        name
        subPackages {
          id
          name
          image {
            default
            thumbnail
            small
            large
          }
          imageBackground
          isEnabled
          isFavorite
          isStarted
          currentProgress
          currentSuccessMeasure
        }
      }
    }
  }
`

/**
 * __useStudentLibraryOverviewQuery__
 *
 * To run a query within a React component, call `useStudentLibraryOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentLibraryOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentLibraryOverviewQuery({
 *   variables: {
 *      subject: // value for 'subject'
 *   },
 * });
 */
export function useStudentLibraryOverviewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<StudentLibraryOverviewQuery, StudentLibraryOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<StudentLibraryOverviewQuery, StudentLibraryOverviewQueryVariables>(
    StudentLibraryOverviewDocument,
    options
  )
}
export function useStudentLibraryOverviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudentLibraryOverviewQuery, StudentLibraryOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<StudentLibraryOverviewQuery, StudentLibraryOverviewQueryVariables>(
    StudentLibraryOverviewDocument,
    options
  )
}
export type StudentLibraryOverviewQueryHookResult = ReturnType<typeof useStudentLibraryOverviewQuery>
export type StudentLibraryOverviewLazyQueryHookResult = ReturnType<typeof useStudentLibraryOverviewLazyQuery>
export type StudentLibraryOverviewQueryResult = Apollo.QueryResult<
  StudentLibraryOverviewQuery,
  StudentLibraryOverviewQueryVariables
>
export const UpdateIsFavoriteFlagDocument = gql`
  mutation updateIsFavoriteFlag($subPackageId: ID!, $input: StudentLibrarySubPackageInput!) {
    updateStudentLibrarySubPackage(subPackageId: $subPackageId, input: $input)
  }
`
export type UpdateIsFavoriteFlagMutationFn = Apollo.MutationFunction<
  UpdateIsFavoriteFlagMutation,
  UpdateIsFavoriteFlagMutationVariables
>

/**
 * __useUpdateIsFavoriteFlagMutation__
 *
 * To run a mutation, you first call `useUpdateIsFavoriteFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIsFavoriteFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIsFavoriteFlagMutation, { data, loading, error }] = useUpdateIsFavoriteFlagMutation({
 *   variables: {
 *      subPackageId: // value for 'subPackageId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIsFavoriteFlagMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateIsFavoriteFlagMutation,
    UpdateIsFavoriteFlagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<UpdateIsFavoriteFlagMutation, UpdateIsFavoriteFlagMutationVariables>(
    UpdateIsFavoriteFlagDocument,
    options
  )
}
export type UpdateIsFavoriteFlagMutationHookResult = ReturnType<typeof useUpdateIsFavoriteFlagMutation>
export type UpdateIsFavoriteFlagMutationResult = Apollo.MutationResult<UpdateIsFavoriteFlagMutation>
export type UpdateIsFavoriteFlagMutationOptions = Apollo.BaseMutationOptions<
  UpdateIsFavoriteFlagMutation,
  UpdateIsFavoriteFlagMutationVariables
>
export const GetStudentRanksDocument = gql`
  query getStudentRanks($classroomId: ID!) {
    weekRanks: studentRanks(classroomId: $classroomId, timeView: WEEK) {
      __typename @skip(if: true)
      id
      points
      currentStudent
      student {
        userProfile {
          id
          pictureUrl
          firstName
          lastName
        }
      }
    }
    monthRanks: studentRanks(classroomId: $classroomId, timeView: MONTH) {
      __typename @skip(if: true)
      id
      points
      currentStudent
      student {
        userProfile {
          id
          pictureUrl
          firstName
          lastName
        }
      }
    }
    overallRanks: studentRanks(classroomId: $classroomId) {
      __typename @skip(if: true)
      id
      points
      currentStudent
      student {
        userProfile {
          id
          pictureUrl
          firstName
          lastName
        }
      }
    }
  }
`

/**
 * __useGetStudentRanksQuery__
 *
 * To run a query within a React component, call `useGetStudentRanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentRanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentRanksQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *   },
 * });
 */
export function useGetStudentRanksQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetStudentRanksQuery, GetStudentRanksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetStudentRanksQuery, GetStudentRanksQueryVariables>(
    GetStudentRanksDocument,
    options
  )
}
export function useGetStudentRanksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStudentRanksQuery, GetStudentRanksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetStudentRanksQuery, GetStudentRanksQueryVariables>(
    GetStudentRanksDocument,
    options
  )
}
export type GetStudentRanksQueryHookResult = ReturnType<typeof useGetStudentRanksQuery>
export type GetStudentRanksLazyQueryHookResult = ReturnType<typeof useGetStudentRanksLazyQuery>
export type GetStudentRanksQueryResult = Apollo.QueryResult<GetStudentRanksQuery, GetStudentRanksQueryVariables>
export const AddMeToClassroomDocument = gql`
  mutation addMeToClassroom($classroomId: ID!, $classroomCode: String!) {
    addMeToClassroom(classroomId: $classroomId, classroomCode: $classroomCode)
  }
`
export type AddMeToClassroomMutationFn = Apollo.MutationFunction<
  AddMeToClassroomMutation,
  AddMeToClassroomMutationVariables
>

/**
 * __useAddMeToClassroomMutation__
 *
 * To run a mutation, you first call `useAddMeToClassroomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMeToClassroomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMeToClassroomMutation, { data, loading, error }] = useAddMeToClassroomMutation({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *      classroomCode: // value for 'classroomCode'
 *   },
 * });
 */
export function useAddMeToClassroomMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddMeToClassroomMutation, AddMeToClassroomMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<AddMeToClassroomMutation, AddMeToClassroomMutationVariables>(
    AddMeToClassroomDocument,
    options
  )
}
export type AddMeToClassroomMutationHookResult = ReturnType<typeof useAddMeToClassroomMutation>
export type AddMeToClassroomMutationResult = Apollo.MutationResult<AddMeToClassroomMutation>
export type AddMeToClassroomMutationOptions = Apollo.BaseMutationOptions<
  AddMeToClassroomMutation,
  AddMeToClassroomMutationVariables
>
export const AddUserRoleDocument = gql`
  mutation addUserRole($userRole: UserRole!) {
    addUserRole(userRole: $userRole)
  }
`
export type AddUserRoleMutationFn = Apollo.MutationFunction<AddUserRoleMutation, AddUserRoleMutationVariables>

/**
 * __useAddUserRoleMutation__
 *
 * To run a mutation, you first call `useAddUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserRoleMutation, { data, loading, error }] = useAddUserRoleMutation({
 *   variables: {
 *      userRole: // value for 'userRole'
 *   },
 * });
 */
export function useAddUserRoleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddUserRoleMutation, AddUserRoleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<AddUserRoleMutation, AddUserRoleMutationVariables>(AddUserRoleDocument, options)
}
export type AddUserRoleMutationHookResult = ReturnType<typeof useAddUserRoleMutation>
export type AddUserRoleMutationResult = Apollo.MutationResult<AddUserRoleMutation>
export type AddUserRoleMutationOptions = Apollo.BaseMutationOptions<AddUserRoleMutation, AddUserRoleMutationVariables>
export const UpdateAppSettingsDocument = gql`
  mutation updateAppSettings($applicationSettings: ApplicationSettingsInput) {
    updateAppSettings(applicationSettings: $applicationSettings) {
      appLanguage
    }
  }
`
export type UpdateAppSettingsMutationFn = Apollo.MutationFunction<
  UpdateAppSettingsMutation,
  UpdateAppSettingsMutationVariables
>

/**
 * __useUpdateAppSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateAppSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppSettingsMutation, { data, loading, error }] = useUpdateAppSettingsMutation({
 *   variables: {
 *      applicationSettings: // value for 'applicationSettings'
 *   },
 * });
 */
export function useUpdateAppSettingsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAppSettingsMutation, UpdateAppSettingsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<UpdateAppSettingsMutation, UpdateAppSettingsMutationVariables>(
    UpdateAppSettingsDocument,
    options
  )
}
export type UpdateAppSettingsMutationHookResult = ReturnType<typeof useUpdateAppSettingsMutation>
export type UpdateAppSettingsMutationResult = Apollo.MutationResult<UpdateAppSettingsMutation>
export type UpdateAppSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppSettingsMutation,
  UpdateAppSettingsMutationVariables
>
export const UpdateUserProfileDocument = gql`
  mutation updateUserProfile($userProfile: UserProfileInput) {
    updateUserProfile(userProfile: $userProfile) {
      id
      firstName
      lastName
      birthday
      pictureUrl
    }
  }
`
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      userProfile: // value for 'userProfile'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(
    UpdateUserProfileDocument,
    options
  )
}
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>
export const AnswerQuizQuestionDocument = gql`
  mutation answerQuizQuestion($quizId: ID!, $questionId: ID!, $answer: Int!, $timestamp: DateTime) {
    answerQuizQuestion(quizId: $quizId, questionId: $questionId, answer: $answer, timestamp: $timestamp)
  }
`
export type AnswerQuizQuestionMutationFn = Apollo.MutationFunction<
  AnswerQuizQuestionMutation,
  AnswerQuizQuestionMutationVariables
>

/**
 * __useAnswerQuizQuestionMutation__
 *
 * To run a mutation, you first call `useAnswerQuizQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerQuizQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerQuizQuestionMutation, { data, loading, error }] = useAnswerQuizQuestionMutation({
 *   variables: {
 *      quizId: // value for 'quizId'
 *      questionId: // value for 'questionId'
 *      answer: // value for 'answer'
 *      timestamp: // value for 'timestamp'
 *   },
 * });
 */
export function useAnswerQuizQuestionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AnswerQuizQuestionMutation, AnswerQuizQuestionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<AnswerQuizQuestionMutation, AnswerQuizQuestionMutationVariables>(
    AnswerQuizQuestionDocument,
    options
  )
}
export type AnswerQuizQuestionMutationHookResult = ReturnType<typeof useAnswerQuizQuestionMutation>
export type AnswerQuizQuestionMutationResult = Apollo.MutationResult<AnswerQuizQuestionMutation>
export type AnswerQuizQuestionMutationOptions = Apollo.BaseMutationOptions<
  AnswerQuizQuestionMutation,
  AnswerQuizQuestionMutationVariables
>
export const GetQuizGameDocument = gql`
  query getQuizGame($quizId: ID!) {
    quizGame(quizId: $quizId) {
      id
      name
      gameState
      numOfQuestions
      secondsPerQuestion
      showQuestionInClient
      countdownInSeconds
      currentQuestion {
        id
        index
        assignment {
          __typename @skip(if: true)
          id
          images {
            medium
            large
            default
          }
          soundURL
          videoURL
          language
          text
        }
        assignmentType
        responses {
          __typename @skip(if: true)
          id
          text
        }
        numOfAnswers
        questionResult {
          correctResponseId
          answerDistribution
        }
        hasNext
      }
      connectedPlayers {
        id
        lastName
        firstName
        pictureUrl
        newPosition
        oldPosition
        totalPoints
        newPoints
      }
    }
  }
`

/**
 * __useGetQuizGameQuery__
 *
 * To run a query within a React component, call `useGetQuizGameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuizGameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuizGameQuery({
 *   variables: {
 *      quizId: // value for 'quizId'
 *   },
 * });
 */
export function useGetQuizGameQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetQuizGameQuery, GetQuizGameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetQuizGameQuery, GetQuizGameQueryVariables>(GetQuizGameDocument, options)
}
export function useGetQuizGameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetQuizGameQuery, GetQuizGameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetQuizGameQuery, GetQuizGameQueryVariables>(GetQuizGameDocument, options)
}
export type GetQuizGameQueryHookResult = ReturnType<typeof useGetQuizGameQuery>
export type GetQuizGameLazyQueryHookResult = ReturnType<typeof useGetQuizGameLazyQuery>
export type GetQuizGameQueryResult = Apollo.QueryResult<GetQuizGameQuery, GetQuizGameQueryVariables>
export const JoinQuizGameDocument = gql`
  mutation joinQuizGame($code: String!) {
    joinQuizGame(code: $code) {
      id
    }
  }
`
export type JoinQuizGameMutationFn = Apollo.MutationFunction<JoinQuizGameMutation, JoinQuizGameMutationVariables>

/**
 * __useJoinQuizGameMutation__
 *
 * To run a mutation, you first call `useJoinQuizGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinQuizGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinQuizGameMutation, { data, loading, error }] = useJoinQuizGameMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useJoinQuizGameMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<JoinQuizGameMutation, JoinQuizGameMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<JoinQuizGameMutation, JoinQuizGameMutationVariables>(
    JoinQuizGameDocument,
    options
  )
}
export type JoinQuizGameMutationHookResult = ReturnType<typeof useJoinQuizGameMutation>
export type JoinQuizGameMutationResult = Apollo.MutationResult<JoinQuizGameMutation>
export type JoinQuizGameMutationOptions = Apollo.BaseMutationOptions<
  JoinQuizGameMutation,
  JoinQuizGameMutationVariables
>
export const LeaveQuizGameDocument = gql`
  mutation leaveQuizGame($quizId: ID!) {
    leaveQuizGame(quizId: $quizId)
  }
`
export type LeaveQuizGameMutationFn = Apollo.MutationFunction<LeaveQuizGameMutation, LeaveQuizGameMutationVariables>

/**
 * __useLeaveQuizGameMutation__
 *
 * To run a mutation, you first call `useLeaveQuizGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveQuizGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveQuizGameMutation, { data, loading, error }] = useLeaveQuizGameMutation({
 *   variables: {
 *      quizId: // value for 'quizId'
 *   },
 * });
 */
export function useLeaveQuizGameMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveQuizGameMutation, LeaveQuizGameMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<LeaveQuizGameMutation, LeaveQuizGameMutationVariables>(
    LeaveQuizGameDocument,
    options
  )
}
export type LeaveQuizGameMutationHookResult = ReturnType<typeof useLeaveQuizGameMutation>
export type LeaveQuizGameMutationResult = Apollo.MutationResult<LeaveQuizGameMutation>
export type LeaveQuizGameMutationOptions = Apollo.BaseMutationOptions<
  LeaveQuizGameMutation,
  LeaveQuizGameMutationVariables
>
export const QuizGameClientDocument = gql`
  subscription QuizGameClient($qrCode: String!) {
    quizGameClient(qrCode: $qrCode) {
      id
      name
      gameState
      numOfQuestions
      secondsPerQuestion
      showQuestionInClient
      countdownInSeconds
      currentQuestion {
        id
        index
        assignment {
          id
          images {
            medium
            large
            default
          }
          soundURL
          videoURL
          language
          text
        }
        assignmentType
        responses {
          id
          text
        }
        numOfAnswers
        questionResult {
          correctResponseId
          answerDistribution
        }
        hasNext
      }
      connectedPlayers {
        id
        lastName
        firstName
        pictureUrl
        newPosition
        oldPosition
        totalPoints
        newPoints
      }
    }
  }
`

/**
 * __useQuizGameClientSubscription__
 *
 * To run a query within a React component, call `useQuizGameClientSubscription` and pass it any options that fit your needs.
 * When your component renders, `useQuizGameClientSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuizGameClientSubscription({
 *   variables: {
 *      qrCode: // value for 'qrCode'
 *   },
 * });
 */
export function useQuizGameClientSubscription(
  baseOptions: ApolloReactHooks.SubscriptionHookOptions<QuizGameClientSubscription, QuizGameClientSubscriptionVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSubscription<QuizGameClientSubscription, QuizGameClientSubscriptionVariables>(
    QuizGameClientDocument,
    options
  )
}
export type QuizGameClientSubscriptionHookResult = ReturnType<typeof useQuizGameClientSubscription>
export type QuizGameClientSubscriptionResult = Apollo.SubscriptionResult<QuizGameClientSubscription>
export const GetActiveTasksDocument = gql`
  query getActiveTasks($classroomId: ID) {
    tasks(isActive: true, classroomId: $classroomId) {
      id
      name
      progress
      successMeasure
      state
      remainingTimeSeconds
      learningAssignment {
        id
        name
        skills
        endDateAndTime
        startDateAndTime
        assignedByTeacher
        learningFeatureCount
        imageURL
        difficulty
        level
        state
        type
        subject
      }
    }
  }
`

/**
 * __useGetActiveTasksQuery__
 *
 * To run a query within a React component, call `useGetActiveTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveTasksQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *   },
 * });
 */
export function useGetActiveTasksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetActiveTasksQuery, GetActiveTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetActiveTasksQuery, GetActiveTasksQueryVariables>(GetActiveTasksDocument, options)
}
export function useGetActiveTasksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetActiveTasksQuery, GetActiveTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetActiveTasksQuery, GetActiveTasksQueryVariables>(
    GetActiveTasksDocument,
    options
  )
}
export type GetActiveTasksQueryHookResult = ReturnType<typeof useGetActiveTasksQuery>
export type GetActiveTasksLazyQueryHookResult = ReturnType<typeof useGetActiveTasksLazyQuery>
export type GetActiveTasksQueryResult = Apollo.QueryResult<GetActiveTasksQuery, GetActiveTasksQueryVariables>
export const GetCompletedLearningExecutionDocument = gql`
  query getCompletedLearningExecution($learningExecutionId: ID!) {
    completedLearningExecution(learningExecutionId: $learningExecutionId) {
      studyTimeSeconds
      learningExecution {
        id
        name
        progress
        successMeasure
        state
        learningAssignment {
          image {
            thumbnail
            small
            medium
            large
            default
          }
          type
          endDateAndTime
          learningFeatureCount
        }
      }
      totalSkippedCount
      answers {
        flashCardAssignmentType
        flashCardResponseType
        flashCardFullScreenType
        userAnswers {
          assignmentText
          userAnswer
          correctAnswer
          result
        }
        skipCount
        tryCount
        points
      }
    }
  }
`

/**
 * __useGetCompletedLearningExecutionQuery__
 *
 * To run a query within a React component, call `useGetCompletedLearningExecutionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompletedLearningExecutionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompletedLearningExecutionQuery({
 *   variables: {
 *      learningExecutionId: // value for 'learningExecutionId'
 *   },
 * });
 */
export function useGetCompletedLearningExecutionQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetCompletedLearningExecutionQuery,
    GetCompletedLearningExecutionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetCompletedLearningExecutionQuery, GetCompletedLearningExecutionQueryVariables>(
    GetCompletedLearningExecutionDocument,
    options
  )
}
export function useGetCompletedLearningExecutionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCompletedLearningExecutionQuery,
    GetCompletedLearningExecutionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetCompletedLearningExecutionQuery, GetCompletedLearningExecutionQueryVariables>(
    GetCompletedLearningExecutionDocument,
    options
  )
}
export type GetCompletedLearningExecutionQueryHookResult = ReturnType<typeof useGetCompletedLearningExecutionQuery>
export type GetCompletedLearningExecutionLazyQueryHookResult = ReturnType<
  typeof useGetCompletedLearningExecutionLazyQuery
>
export type GetCompletedLearningExecutionQueryResult = Apollo.QueryResult<
  GetCompletedLearningExecutionQuery,
  GetCompletedLearningExecutionQueryVariables
>
export const GetFinishedTasksDocument = gql`
  query getFinishedTasks($classroomId: ID, $page: PageableInput!) {
    tasks(isActive: false, classroomId: $classroomId, page: $page) {
      id
      name
      progress
      successMeasure
      state
      remainingTimeSeconds
      learningAssignment {
        id
        name
        skills
        endDateAndTime
        startDateAndTime
        assignedByTeacher
        learningFeatureCount
        imageURL
        difficulty
        level
        state
        type
        subject
      }
    }
  }
`

/**
 * __useGetFinishedTasksQuery__
 *
 * To run a query within a React component, call `useGetFinishedTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinishedTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinishedTasksQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetFinishedTasksQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetFinishedTasksQuery, GetFinishedTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetFinishedTasksQuery, GetFinishedTasksQueryVariables>(
    GetFinishedTasksDocument,
    options
  )
}
export function useGetFinishedTasksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFinishedTasksQuery, GetFinishedTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetFinishedTasksQuery, GetFinishedTasksQueryVariables>(
    GetFinishedTasksDocument,
    options
  )
}
export type GetFinishedTasksQueryHookResult = ReturnType<typeof useGetFinishedTasksQuery>
export type GetFinishedTasksLazyQueryHookResult = ReturnType<typeof useGetFinishedTasksLazyQuery>
export type GetFinishedTasksQueryResult = Apollo.QueryResult<GetFinishedTasksQuery, GetFinishedTasksQueryVariables>
export const GetRevisionLearningExecutionsDocument = gql`
  query getRevisionLearningExecutions {
    revisionLearningExecutions {
      id
      name
      progress
      successMeasure
      state
      learningAssignment {
        id
        name
        skills
        endDateAndTime
        startDateAndTime
        assignedByTeacher
        learningFeatureCount
        image {
          thumbnail
          small
          medium
          large
          default
        }
        difficulty
        level
        state
        type
      }
    }
  }
`

/**
 * __useGetRevisionLearningExecutionsQuery__
 *
 * To run a query within a React component, call `useGetRevisionLearningExecutionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRevisionLearningExecutionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRevisionLearningExecutionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRevisionLearningExecutionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetRevisionLearningExecutionsQuery,
    GetRevisionLearningExecutionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetRevisionLearningExecutionsQuery, GetRevisionLearningExecutionsQueryVariables>(
    GetRevisionLearningExecutionsDocument,
    options
  )
}
export function useGetRevisionLearningExecutionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRevisionLearningExecutionsQuery,
    GetRevisionLearningExecutionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetRevisionLearningExecutionsQuery, GetRevisionLearningExecutionsQueryVariables>(
    GetRevisionLearningExecutionsDocument,
    options
  )
}
export type GetRevisionLearningExecutionsQueryHookResult = ReturnType<typeof useGetRevisionLearningExecutionsQuery>
export type GetRevisionLearningExecutionsLazyQueryHookResult = ReturnType<
  typeof useGetRevisionLearningExecutionsLazyQuery
>
export type GetRevisionLearningExecutionsQueryResult = Apollo.QueryResult<
  GetRevisionLearningExecutionsQuery,
  GetRevisionLearningExecutionsQueryVariables
>
export const GetStudentInfoDocument = gql`
  query getStudentInfo {
    studentInfo {
      student {
        userProfile {
          id
          pictureUrl
          firstName
          lastName
          username
          birthday
          email
        }
        applicationSettings {
          appLanguage
        }
        classrooms {
          id
          name
          subject
        }
      }
      selectedLearning {
        id
        name
        subject
      }
      allLearning {
        id
        name
        subject
      }
    }
  }
`

/**
 * __useGetStudentInfoQuery__
 *
 * To run a query within a React component, call `useGetStudentInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStudentInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetStudentInfoQuery, GetStudentInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetStudentInfoQuery, GetStudentInfoQueryVariables>(GetStudentInfoDocument, options)
}
export function useGetStudentInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStudentInfoQuery, GetStudentInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetStudentInfoQuery, GetStudentInfoQueryVariables>(
    GetStudentInfoDocument,
    options
  )
}
export type GetStudentInfoQueryHookResult = ReturnType<typeof useGetStudentInfoQuery>
export type GetStudentInfoLazyQueryHookResult = ReturnType<typeof useGetStudentInfoLazyQuery>
export type GetStudentInfoQueryResult = Apollo.QueryResult<GetStudentInfoQuery, GetStudentInfoQueryVariables>
